import ApiClient from "../../../services/ApiClient";

export const loadGamesCategories = (limit: boolean, value: number) => {
  let url = "games?";
  if (limit) {
    url += "limit=" + value.toString();
  }
  return ApiClient.get(url);
};

export const loadGameUrl = (gameId: number) => ApiClient.get(`games/${gameId}/url`);

export const loadGameCodeUrl = (code: string) => ApiClient.get(`games/${code}/url`);

export const listWhoswinning = () => ApiClient.get("games/whos-winning");

export const loadVideoBingoStatus = (gameId: number | string) =>
  ApiClient.get(`games/video-bingo/status/${gameId}`);

export const loadFastBingoStatus = () => ApiClient.get(`games/fast-bingo/status`);
