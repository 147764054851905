import React, { FC, useState, useEffect, useRef, Fragment } from "react";

import Card from "../../components/Card";
import { useSelector, useDispatch } from "react-redux";

import "./FastbingoV1.scss";
import "./Fastbingo.scss";
import classNames from "classnames";
import { IRootState } from "../../state/ducks/types";
import {
  closeModalAction,
  getGameCards,
  getPlayerCards,
  resetGameReady,
  startFastBingoGame,
  //refreshDrawnBalls,
} from "../../state/ducks/fastbingo/actions";
import Button from "../../components/Button";

import ComprarCartela from "./ComprarCartela";
import { EnumPlayerCardStatus } from "../../state/ducks/games/types";
import ComoJogar from "./ComoJogar";

const FastbingoV1: FC = () => {
  const dispatch = useDispatch();

  const audioRef = useRef<HTMLAudioElement>(null);

  const lastDrawNumberRef = useRef<HTMLInputElement>(null);
  const [lastDrawNumber, setLastDrawNumber] = useState<string | null>(null);
  const [intervalDraw, setIntervalDraw] = useState<any>(null);
  const [controlEndedGamePopup, setControlEndedGamePopup] = useState<boolean>(false);
  const [status, setStatus] = useState<EnumPlayerCardStatus>(EnumPlayerCardStatus.ACTIVE);

  const {
    playerCards,
    modal,
    drawnData: {
      drawnNumbers,
      listPlayerCards,
      lastNumber,
      winners,
      bingoLineNumber,
      bingoCornerNumber,
    },
    loading,
    gameReady,
  } = useSelector((state: IRootState) => state.fastbingo);

  const totalCard: number = 4;
  const allowBuyCard: boolean = playerCards.length < totalCard;

  const [drawBalls, setDrawBalls] = useState<any>([]);
  const [localDrawnNumbers, setLocalDrawnNumbers] = useState<any>([]);
  //const [cardDrawnBalls, setCardDrawnBalls] = useState<any>([]);
  const [endedGame, setEndedGame] = useState<boolean>(false);
  const [startedGame, setStartedGame] = useState<boolean>(false);
  const [restartedBtn, setRestartedBtn] = useState<boolean>(false);
  const [lastBallAdded, setLastBallAdded] = useState<number | null>(null);
  const [startGameReadyAnimation, setStartGameReadyAnimation] = useState<boolean>(false);
  const [allowStartDraw, setAllowStartDraw] = useState<boolean>(false);

  const [totalDrawNumbers, setTotalDrawNumbers] = useState<number>(0);

  useEffect(() => {
    if (gameReady && listPlayerCards?.length > 0) {
      setStartGameReadyAnimation(true);

      setTimeout(() => {
        setStartGameReadyAnimation(false);
        setAllowStartDraw(true);
      }, 3000);
    }
  }, [gameReady, listPlayerCards]);

  useEffect(() => {
    if (controlEndedGamePopup) {
      const winner = listPlayerCards?.some((card) => {
        return (
          card.status_line === "WINNER" ||
          card.status_corner === "WINNER" ||
          card.status_full === "WINNER"
        );
      });
      if (winner) {
        setStatus(EnumPlayerCardStatus.WINNER);
      } else {
        setStatus(EnumPlayerCardStatus.LOSER);
      }
    }
  }, [listPlayerCards, controlEndedGamePopup]);

  useEffect(() => {
    dispatch(closeModalAction());
    dispatch(getPlayerCards("bngfast1"));
  }, [dispatch]);

  useEffect(() => {
    if (drawnNumbers && drawnNumbers.length > 0) {
      setDrawBalls(drawnNumbers);
    }
  }, [drawnNumbers, localDrawnNumbers, bingoLineNumber]);

  useEffect(() => {
    if (lastDrawNumber !== "" && lastDrawNumber !== null) {
      let classnameColor: string = "";
      const convertLastDrawnNumber: number = Number(lastDrawNumber);
      const nodeDrawNumber = lastDrawNumberRef.current;
      nodeDrawNumber?.classList?.remove("reveal-out");
      classnameColor =
        convertLastDrawnNumber % 3 === 0
          ? "blue"
          : (convertLastDrawnNumber + 1) % 3 === 0
          ? "green"
          : "red";
      setTimeout(() => {
        nodeDrawNumber?.classList?.add(classnameColor);
        nodeDrawNumber?.classList?.add("active");
        setTimeout(() => {
          nodeDrawNumber?.classList?.remove("active");
          nodeDrawNumber?.classList?.add("reveal-out");
          nodeDrawNumber?.classList?.remove("red");
          nodeDrawNumber?.classList?.remove("green");
          nodeDrawNumber?.classList?.remove("blue");
        }, 1500);
      }, 250);
    }
  }, [lastDrawNumber]);

  useEffect(() => {
    if (drawBalls.length > 0 && allowStartDraw) {
      if (intervalDraw === null) {
        setIntervalDraw(
          setInterval(() => {
            if (drawBalls[0]) {
              setTotalDrawNumbers((total) => total + 1);
              setLocalDrawnNumbers((localDrawnNumbers: any[]) =>
                localDrawnNumbers.concat(drawBalls[0])
              );
              checkDrawBall(zeroFill(drawBalls[0]));
              setLastDrawNumber(drawBalls[0]);
              setDrawBalls(drawBalls.shift());

              if (drawBalls[0] === lastNumber) {
                setLastBallAdded(drawBalls[0]);
                setDrawBalls([]);
              }
            }
          }, 1000)
        );
      }
    }

    if (drawBalls.length === 0) {
      return () => {
        setAllowStartDraw(false);
        clearInterval(intervalDraw);
        setIntervalDraw(null);
      };
    }
  }, [drawBalls, intervalDraw, lastNumber, allowStartDraw, localDrawnNumbers, totalDrawNumbers]); //cardDrawnBalls

  useEffect(() => {
    if (lastBallAdded !== null) {
      setEndedGame(true);
      dispatch(resetGameReady());
    }
  }, [lastBallAdded, dispatch]);

  useEffect(() => {
    if (endedGame) {
      setTimeout(() => {
        setControlEndedGamePopup(true);
        removeActiveDrawBall();
        setTotalDrawNumbers(0);
        setRestartedBtn(true);
        setTimeout(() => {
          setControlEndedGamePopup(false);
        }, 5000);
      }, 2000);
    }
  }, [dispatch, endedGame]);

  const restartGame = () => {
    setEndedGame(false);
    setLocalDrawnNumbers([]);
    setTotalDrawNumbers(0);
    setLastBallAdded(null);
    setStartedGame(false);
    dispatch(getPlayerCards("bngfast1"));
    setRestartedBtn(false);
    setStatus(EnumPlayerCardStatus.ACTIVE);
  };

  function checkDrawBall(value: string) {
    const numbers = Array.from(document.getElementsByClassName(`number-${value}`));
    for (const n of numbers) {
      n.classList.add("active");
    }
  }

  function removeActiveDrawBall() {
    const numbers = Array.from(document.getElementsByClassName("number"));
    for (const n of numbers) {
      n.classList.remove("active");
    }
  }

  const numbers = () => {
    let elements = [];
    let classnameColor: string = "";
    for (let i = 1; i <= 75; i++) {
      classnameColor = i % 3 === 0 ? "blue" : (i + 1) % 3 === 0 ? "green" : "red";
      elements.push(
        <div className="col" key={i}>
          <span className={classNames(`number number-${zeroFill(i)} color-${classnameColor}`)}>
            {zeroFill(i)}
          </span>
        </div>
      );
    }
    return elements;
  };

  const startGame = () => {
    if (audioRef?.current) {
      audioRef.current.volume = 0.2;
      audioRef.current?.play();
    }
    setStartedGame(true);
    dispatch(startFastBingoGame());
  };

  const showEndedGamePopup = () => {
    clearInterval(intervalDraw);
    let image: string = "";
    const winner = listPlayerCards?.some((card) => {
      return (
        card.status_line === "WINNER" ||
        card.status_corner === "WINNER" ||
        card.status_full === "WINNER"
      );
    });

    let winnerText: { text: string }[] = [];

    listPlayerCards?.forEach((card) => {
      if (card.status_line === "WINNER") {
        winnerText.push({ text: "Linha" });
      }

      if (card.status_corner === "WINNER") {
        winnerText.push({ text: "4 Cantos" });
      }

      if (card.status_full === "WINNER") {
        winnerText.push({ text: "Cartela cheia" });
      }
    });

    let winnerPlainText: string = "";
    if (winner) {
      image = "winner-fastbingo.png";
      winnerPlainText = winnerText
        .map((item) => {
          return item.text;
        })
        .join(", ");
    } else {
      image = "loser-fastbingo.png";
    }

    return (
      <div className="endGamePopup">
        <img src={`/${image}`} alt="Final de jogo fastbingo" />
        {winnerPlainText !== "" && <p className="text">{winnerPlainText}</p>}
      </div>
    );
  };

  function zeroFill(number: number): string {
    var result = number.toString();
    while (result.length < 2) {
      result = "0" + result;
    }
    return result;
  }
  const buyCardsSections = () => {
    return (
      <Fragment>
        <div className="buy-cards">
          {playerCards &&
            playerCards.map((card, key) => (
              <Card
                key={key}
                id={card.id}
                numbers={card.numbers}
                statusFull={status}
                statusBallScreen={EnumPlayerCardStatus.INACTIVE}
                statusLine={
                  localDrawnNumbers.includes(bingoLineNumber)
                    ? listPlayerCards[key]?.status_line
                    : status
                }
                statusCorner={
                  localDrawnNumbers.includes(bingoCornerNumber)
                    ? listPlayerCards[key]?.status_corner
                    : status
                }
                lineBingo={listPlayerCards[key]?.bingo_line}
                cornerBingo={listPlayerCards[key]?.bingo_corner}
                ballsDrawn={localDrawnNumbers}
                fastBingo={true}
                gameCode="fastbingo"
              />
            ))}
          {allowBuyCard &&
            !startedGame &&
            [...Array(totalCard - (playerCards.length ?? 0))].map((_, key) => buyCard(key))}
        </div>
        {!startedGame && <ComoJogar color="#FFF" />}
        {playerCards && playerCards.length > 0 && (
          <div className="row row-btns jcc aic">
            {!startedGame && (
              <Button
                className="button start-game"
                text="INICIAR JOGO"
                onClick={() => startGame()}
              />
            )}

            {restartedBtn && (
              <Button
                className="button start-game"
                text="REINICIAR JOGO"
                onClick={() => restartGame()}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  };

  const buyCard = (key: number) => {
    return (
      <div
        key={key}
        className={`item item-${key} row aic jcc`}
        onClick={() => dispatch(getGameCards())}
      >
        <h3>
          COMPRAR <br /> CARTELAS
        </h3>
      </div>
    );
  };

  const countdownBlock = () => {
    return <div className="countdown row aic jcc"></div>;
  };

  return (
    <div className={classNames("fastbingo fastbingo-v1")}>
      <audio ref={audioRef} src="/audio-fundo2.mp3" loop />
      {startGameReadyAnimation && countdownBlock()}
      {loading && (
        <div className="wrap-loading">{startedGame && <h2>Buscando outros jogadores...</h2>}</div>
      )}
      {endedGame && controlEndedGamePopup && showEndedGamePopup()}
      <div className="container row flex-m-column">
        <div className="col-16 col-m-25 col-m-last">
          <div className="wrap-cards">
            {endedGame && restartedBtn ? (
              <div className="winners">
                <div className="type">
                  <span>Ganhadores Cartela Cheia:</span>{" "}
                  {winners?.full.map((winner) => winner.nickname).join(", ")}
                </div>
                <div className="type">
                  <span>Ganhadores Linha:</span>{" "}
                  {winners?.line.map((winner) => winner.nickname).join(", ")}
                </div>
                <div className="type">
                  <span>Ganhadores Quatro Cantos:</span>{" "}
                  {winners?.corner.map((winner) => winner.nickname).join(", ")}
                </div>
              </div>
            ) : (
              <div className="big-number-draw row aic jcc">
                <span ref={lastDrawNumberRef}>{lastDrawNumber}</span>
              </div>
            )}
            {totalDrawNumbers > 0 && (
              <div className="totalDrawNumbers">
                {totalDrawNumbers} bola{`${totalDrawNumbers > 1 ? "s" : ""}`} sorteada
                {`${totalDrawNumbers > 1 ? "s" : ""}`}!
              </div>
            )}
            <div className="main-list-card row flex-wrap">{numbers()}</div>
          </div>
        </div>
        <div className="col-9 col-last col-m-25 col-m-last section-cards">
          <img src="/logo-fastbingo.png" alt="Fastbingo" className="logoFastbingo" />
          {modal?.isOpen ? <ComprarCartela /> : buyCardsSections()}
        </div>
      </div>
    </div>
  );
};

export default FastbingoV1;
