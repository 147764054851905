import React, { FC, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IRootState } from "../../state/ducks/types";
import { closeModalAction, postGameCards } from "../../state/ducks/fastbingo/actions";

import { formatCurrency } from "../../utils/Functions";
import Button from "../../components/Button";
import CartelaItem from "./CartelaItem";

const ComprarCartela: FC = () => {
  const dispatch = useDispatch();
  const { cards, error } = useSelector((state: IRootState) => state.fastbingo);
  const [controlActiveCard, setControlActiveCard] = useState<number | null>(null);

  const [selectedCardsQtd, setSelectedCardsQtd] = useState(() => {
    return cards.reduce((aux: any, item) => {
      aux[item.id] = 0;
      return aux;
    }, {});
  });

  const updateSelectedCard = (id: number, quantity: number) => {
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { value } = e.target;

    if (/^\d+$/.test(value)) {
      const quantity = parseInt(value);
      updateSelectedCard(id, quantity);
      if (quantity === 0) setControlActiveCard(null);
      else setControlActiveCard(id);
    }
  };

  const handlerPlayGame = () => {
    const cards: any = Object.entries(selectedCardsQtd)
      .filter(([id, quantity]) => Number(quantity) > 0)
      .map(([id, quantity]) => {
        return { id, quantity };
      });
    const params = {
      game_code: "bngfast1",
      cards: cards,
    };

    dispatch(postGameCards(params));
    eraseSelectedCards();
  };

  const handlerClose = () => {
    dispatch(closeModalAction());
    eraseSelectedCards();
  };

  const eraseSelectedCards = () => {
    setControlActiveCard(null);
    setSelectedCardsQtd(() => {
      return cards.reduce((aux: any, item) => {
        aux[item.id] = 0;
        return aux;
      }, {});
    });
  };

  const handleArrowDown = (id: number) => {
    const quantity: number = (selectedCardsQtd[id] ?? 0) - 1;
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
    if (quantity === 0) setControlActiveCard(null);
  };

  const handleArrowTop = (id: number) => {
    const quantity: number = (selectedCardsQtd[id] ?? 0) + 1;
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
    setControlActiveCard(id);
  };

  return (
    <div className="wrapComprarCartela">
      <div className="row justify-content-end">
        <span className="close" onClick={() => handlerClose()}>
          X
        </span>
      </div>
      <div className="row flex-wrap">
        {cards &&
          cards.map((card, k) => (
            <Fragment key={k}>
              <CartelaItem
                id={card.id}
                image={`/${card.description}-premio-fastbingo.png`}
                price={formatCurrency(card.price)}
                value={selectedCardsQtd[card.id] ?? 0}
                inputChangeValue={handleInputChange}
                onClickArrowBottom={() => handleArrowDown(card.id)}
                onClickArrowTop={() => handleArrowTop(card.id)}
                controlActiveCard={controlActiveCard}
              />
            </Fragment>
          ))}
      </div>
      {error && <div className="error">{error}</div>}
      <div className="row row-btns jcc aic">
        <Button text="COMPRAR CARTELA" onClick={() => handlerPlayGame()} />
      </div>
    </div>
  );
};

export default ComprarCartela;
