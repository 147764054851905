import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";

import Bubble from "../../components/Bubble";
import "./Chat.scss";
import { chatFlush, send } from "../../state/ducks/webSocket/actions";
import { globalGetPhrasesChat } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";
import ChatMessage from "../../components/ChatMessage";
import classNames from "classnames";

const Chat: FC = () => {
  const dispatch = useDispatch();
  const { chat } = useSelector((state: IRootState) => state.webSocket);
  const {
    chat: { phrases },
  } = useSelector((state: IRootState) => state.global);
  const [chatTimeout, setChatTimeout] = useState(false);
  const [showMoreMessages, setShowMoreMessages] = useState<boolean>(false);

  useEffect(() => {
    dispatch(globalGetPhrasesChat());
  }, [dispatch]);

  useEffect(() => {
    if (chat.length > 50) {
      dispatch(chatFlush(chat.slice(Math.max(chat.length - 30, 1))));
    }
  }, [dispatch, chat]);

  const handleSendMessage = useCallback(
    (bubble: string) => {
      if (!chatTimeout) {
        dispatch(send("CHAT_MESSAGE", bubble));
        setChatTimeout(true);
        setTimeout(() => {
          setChatTimeout(false);
        }, 3000);
      }
    },
    [dispatch, chatTimeout]
  );

  return (
    <div className="chat">
      <ScrollableFeed className="timeline" forceScroll={true}>
        {chat.map((message, k) => (
          <ChatMessage
            key={`${k}_message`}
            message={message.message}
            nickname={message.nickname}
            avatar={message.avatar}
            level={message.level}
          />
        ))}
      </ScrollableFeed>
      <div className={classNames("wrap-talk", { showmore: showMoreMessages })}>
        <div className="d-none d-m-block">
          <div
            className="show-messages row jcc aic"
            onClick={() => setShowMoreMessages(!showMoreMessages)}
          >
            <img src="/bubble-chat.png" alt="Exibir mensagens" />
            <span className="arrow"></span>
          </div>
        </div>
        <div className="talk">
          {phrases.map((bubble, k) => (
            <Bubble
              key={bubble.id}
              text={bubble.message}
              onClick={() => handleSendMessage(bubble.message)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chat;
