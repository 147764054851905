import ApiClient from "../../../services/ApiClient";
import { rejectApi, urlEncode } from "../../../utils/Functions";
import {
  IForgotService,
  ILoginApi,
  ILoginService,
  ISignUpApi,
  ISignUpService,
  IEditService,
  IEditApi,
  IExchangeApi,
  ICodeApi,
  IGetPlayerIndicationsApi,
  IGetPlayerIndicationsService,
  IUpdatePhoneService,
  IGetNotificationsService,
} from "./types";

import { format } from "date-fns";
import { DATE_FORMAT } from "../../../config/constants";
import ptBR from "date-fns/locale/pt-BR";

export default class Global {
  static login(data: ILoginService) {
    return new Promise<ILoginApi>((resolve, reject) => {
      ApiClient.post("/login", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static edit(data: IEditService) {
    return new Promise<IEditApi>((resolve, reject) => {
      ApiClient.patch("/players", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static validationSmsCode(data: any) {
    return new Promise<ICodeApi>((resolve, reject) => {
      ApiClient.post("/players/activate", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static validationSmsCodeResend(data: any) {
    return new Promise<ICodeApi>((resolve, reject) => {
      ApiClient.post("/players/activate/resend/", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static exchangeTrade(data: any) {
    return new Promise<IExchangeApi>((resolve, reject) => {
      ApiClient.post("/exchanges", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static forgot(data: IForgotService) {
    return new Promise<ILoginApi>((resolve, reject) => {
      ApiClient.post("/players/forgot", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static signUp(data: ISignUpService) {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.post("/players", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getSaldo() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("contas/saldo")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getChatPhrases() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("chat-phrases")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getAvatars() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("avatars")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getLevels() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("players/levels")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getStream() {
    return ApiClient.get("link");
  }

  static getPlayer() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("players")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getBanners() {
    return new Promise<ISignUpApi>((resolve, reject) => {
      ApiClient.get("banners")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getPlayerIndications(data?: IGetPlayerIndicationsService) {
    return new Promise<IGetPlayerIndicationsApi>((resolve, reject) => {
      let dateFrom = undefined;
      if (data?.dateFrom) {
        dateFrom = format(new Date(data?.dateFrom), DATE_FORMAT, {
          locale: ptBR,
        });
      }

      let dateTo = undefined;
      if (data?.dateTo) {
        dateTo = format(new Date(data?.dateTo), DATE_FORMAT, {
          locale: ptBR,
        });
      }

      ApiClient.get(`/players/indications?date_from=${dateFrom ?? ""}&date_to=${dateTo ?? ""}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static updatePhone(data: IUpdatePhoneService) {
    return new Promise((resolve, reject) => {
      ApiClient.patch("/players/activate/update-phone", data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getNotifications(data: IGetNotificationsService) {
    return new Promise((resolve, reject) => {
      ApiClient.get(`/players/notifications?${urlEncode(data)}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }

  static getUnreadNotifications() {
    return new Promise((resolve, reject) => {
      ApiClient.get("/players/notifications/unread")
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => rejectApi(error, reject));
    });
  }
}

export const postLogout = () => ApiClient.post("players/logout", {});
