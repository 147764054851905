import { IReduxAction } from "../../../global";
import {
  GET_GAMES_CARDS,
  GET_GAMES_CARDS_ERROR,
  GET_GAMES_CARDS_FULLFILLED,
  OPEN_MODAL,
  CLOSE_MODAL,
  GAMES_CARDS,
  GAMES_CARDS_ERROR,
  GAMES_CARDS_FULLFILLED,
  ICard,
  IPlayerCard,
  GAMES_PLAYER_CARDS,
  GAMES_PLAYER_CARDS_FULLFILLED,
  GAMES_PLAYER_CARDS_ERROR,
  GAMES_RESET_PLAYER_CARDS,
  SET_BALL_SCREEN_ERROR,
  SET_BALL_SCREEN_SUCCESS,
  SET_BALL_SCREEN,
  ISetBallScreenAction,
} from "./types";

export const postGameCards = (params: {}): IReduxAction => ({
  type: GAMES_CARDS,
  payload: {
    ...params,
  },
});

export const postGameCardsError = (message: string): IReduxAction => ({
  type: GAMES_CARDS_ERROR,
  payload: {
    message,
  },
});

export const postGameCardsFullfiled = (gameCard: IPlayerCard): IReduxAction => ({
  type: GAMES_CARDS_FULLFILLED,
  payload: {
    gameCard,
  },
});

export const getGameCards = (gameId: number | string): IReduxAction => ({
  type: GET_GAMES_CARDS,
  payload: {
    gameId,
  },
});

export const getGameCardsError = (message: string): IReduxAction => ({
  type: GET_GAMES_CARDS_ERROR,
  payload: {
    message,
  },
});

export const getGamesCardsFullfilled = (cards: ICard[]): IReduxAction => ({
  type: GET_GAMES_CARDS_FULLFILLED,
  payload: {
    cards,
  },
});

export const openModalAction = (): IReduxAction => ({
  type: OPEN_MODAL,
});

export const closeModalAction = (): IReduxAction => ({
  type: CLOSE_MODAL,
});

export const getPlayerCards = (gameId?: number | string): IReduxAction => ({
  type: GAMES_PLAYER_CARDS,
  payload: {
    gameId,
  },
});

export const resetPlayerCards = (): IReduxAction => ({
  type: GAMES_RESET_PLAYER_CARDS,
});

export const getPlayerCardsFullfilled = (cards: IPlayerCard[]): IReduxAction => ({
  type: GAMES_PLAYER_CARDS_FULLFILLED,
  payload: {
    cards,
  },
});

export const getPlayerCardsError = (message: string): IReduxAction => ({
  type: GAMES_PLAYER_CARDS_ERROR,
  payload: {
    message,
  },
});

export const setBallScreenError = (message: string): IReduxAction => ({
  type: SET_BALL_SCREEN_ERROR,
  payload: {
    message,
  },
});

export const setBallScreenSuccess = (): IReduxAction => ({
  type: SET_BALL_SCREEN_SUCCESS,
});

export const setBallScreen = (cardId: number, number: number): ISetBallScreenAction => ({
  type: SET_BALL_SCREEN,
  payload: {
    bingo_game_card_id: cardId,
    ball_screen: number,
  },
});
