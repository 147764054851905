import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  GET_FASTBINGO_CARDS,
  FASTBINGO_CARDS,
  ICard,
  IPlayerCard,
  FASTBINGO_PLAYER_CARDS,
  START_FASTBINGO_GAME,
  IDrawn,
} from "./types";

import {
  getGameCardsError,
  getFastbingoCardsFullfilled,
  postGameCardsError,
  postGameCardsFullfiled,
  closeModalAction,
  openModalAction,
  getPlayerCardsFullfilled,
  getPlayerCardsError,
  startFastBingoGameError,
  startFastBingoGameFullfilled,
} from "./actions";

import { loadGameCards, gameCard, loadPlayerCards, startFastBingoGame } from "./api";
import { IRootState } from "../types";
import { rejectApi } from "../../../utils/Functions";

export function* fastbingoSaga() {
  yield all([
    yield takeLatest(GET_FASTBINGO_CARDS, getGameCards),
    yield takeLatest(FASTBINGO_CARDS, postGameCards),
    yield takeLatest(FASTBINGO_PLAYER_CARDS, getPlayerCards),
    yield takeLatest(START_FASTBINGO_GAME, postStartGame),
  ]);
}

function* postGameCards({ payload }: any) {
  try {
    const { data }: { data: IPlayerCard } = yield call(gameCard, payload);
    yield put(postGameCardsFullfiled(data));
    yield put(closeModalAction());
  } catch (err) {
    const error = rejectApi(err);
    yield put(postGameCardsError(error));
  }
}

function* postStartGame() {
  try {
    const { data }: { data: IDrawn } = yield call(startFastBingoGame);
    yield put(startFastBingoGameFullfilled(data));
  } catch (err) {
    const error = rejectApi(err);
    yield put(startFastBingoGameError(error));
  }
}

function* getGameCards() {
  try {
    const { data }: { data: ICard[] } = yield call(loadGameCards);
    yield put(getFastbingoCardsFullfilled(data));
    yield put(openModalAction());
  } catch (err) {
    yield put(getGameCardsError(err));
  }
}

function* getPlayerCards({ payload }: any) {
  try {
    let { gameId } = payload;
    if (!gameId) {
      const { currentFBGameCode } = yield select((state: IRootState) => state.fastbingo);
      if (currentFBGameCode) {
        gameId = currentFBGameCode;
      }
    }
    if (gameId) {
      const { data }: { data: IPlayerCard[] } = yield call(loadPlayerCards, gameId);
      yield put(getPlayerCardsFullfilled(data));
    }
  } catch (err) {
    yield put(getPlayerCardsError(err));
    console.error("error", err);
  }
}
