import { IReduxAction } from "../../../global";
import { CLOSE_MODAL_WINNER, OPEN_MODAL_WINNER, IModalWinner, OPENED_MODAL_WINNER } from "./types";

const initialState: IModalWinner = {
  isOpenWinner: false,
  opening: false,
};

export default function modalWinnerReducer(
  state = initialState,
  { type }: IReduxAction
): IModalWinner {
  switch (type) {
    case OPEN_MODAL_WINNER:
      return {
        isOpenWinner: true,
        opening: true,
      };

    case OPENED_MODAL_WINNER:
      return {
        isOpenWinner: true,
        opening: false,
      };

    case CLOSE_MODAL_WINNER:
      return {
        isOpenWinner: false,
        opening: false,
      };

    default:
      return state;
  }
}
