import { IReduxAction } from "../../../global";
import {
  GET_FASTBINGO_CARDS,
  GET_FASTBINGO_CARDS_ERROR,
  GET_FASTBINGO_CARDS_FULLFILLED,
  FASTBINGO_CARDS,
  FASTBINGO_CARDS_ERROR,
  FASTBINGO_CARDS_FULLFILLED,
  OPEN_MODAL,
  CLOSE_MODAL,
  IFastbingoState,
  FASTBINGO_PLAYER_CARDS,
  FASTBINGO_PLAYER_CARDS_FULLFILLED,
  FASTBINGO_PLAYER_CARDS_ERROR,
  FASTBINGO_RESET_PLAYER_CARDS,
  START_FASTBINGO_GAME,
  START_FASTBINGO_GAME_ERROR,
  START_FASTBINGO_GAME_FULLFILLED,
  RESET_GAME_READY,
} from "./types";

import { IDrawBallState } from "../drawBall/types";

export const initialState: IFastbingoState = {
  error: "",
  loading: false,
  cards: [],
  playerCards: [],
  currentFBGameCode: "",
  modal: {
    isOpen: false,
  },
  drawnData: {
    drawnNumbers: [],
    listPlayerCards: [],
    lastNumber: null,
    bingoFullNumber: null,
    bingoLineNumber: null,
    bingoCornerNumber: null,
    winners: null,
  },
  gameReady: false,
};

export const initialStateDrawBalls: IDrawBallState = {
  ballsDrawn: undefined,
};

export default function gameCardsReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IFastbingoState | IDrawBallState {
  switch (type) {
    case RESET_GAME_READY:
      return {
        ...state,
        gameReady: false,
      };
    case FASTBINGO_CARDS:
      return {
        ...state,
        loading: true,
        error: "",
        gameReady: false,
      };
    case FASTBINGO_CARDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
      };
    case FASTBINGO_CARDS_FULLFILLED:
      return {
        ...state,
        loading: true,
        playerCards: payload?.gameCard,
      };
    case GET_FASTBINGO_CARDS:
      return {
        ...state,
        loading: true,
        error: "",
        cards: [],
        gameReady: false,
      };
    case GET_FASTBINGO_CARDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
      };
    case GET_FASTBINGO_CARDS_FULLFILLED:
      return {
        ...state,
        loading: false,
        error: "",
        cards: payload?.cards,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: { isOpen: true },
      };
    case CLOSE_MODAL:
      return {
        ...initialState,
        playerCards: state.playerCards,
      };
    case FASTBINGO_PLAYER_CARDS:
      return {
        ...state,
        loading: true,
      };
    case FASTBINGO_PLAYER_CARDS_FULLFILLED:
      return {
        ...state,
        playerCards: payload?.cards,
        loading: false,
      };
    case FASTBINGO_PLAYER_CARDS_ERROR:
    case FASTBINGO_RESET_PLAYER_CARDS:
      return {
        ...state,
        playerCards: [],
        loading: false,
      };
    case START_FASTBINGO_GAME:
      return {
        ...state,
        loading: true,
        gameReady: false,
      };
    case START_FASTBINGO_GAME_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        gameReady: false,
      };
    case START_FASTBINGO_GAME_FULLFILLED:
      return {
        ...state,
        loading: false,
        error: "",
        drawnData: {
          drawnNumbers: payload?.drawNumbers,
          listPlayerCards: payload?.playerCards,
          lastNumber: payload?.lastNumber,
          bingoFullNumber: payload?.bingoFullNumber,
          bingoLineNumber: payload?.bingoLineNumber,
          bingoCornerNumber: payload?.bingoCornerNumber,
          winners: payload?.winners,
        },
        gameReady: true,
      };

    default:
      return state;
  }
}
