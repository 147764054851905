import { IReduxAction } from "../../../global";
import {
  GLOBAL_LOGGED_IN,
  GLOBAL_LOGIN,
  GLOBAL_LOGIN_REJECT,
  GLOBAL_LOGIN_INACTIVE,
  GLOBAL_LOGOUT,
  GLOBAL_MODAL_TOGGLE,
  GLOBAL_SET_AUTH_FORM,
  GLOBAL_SIGN_UP,
  GLOBAL_SIGN_UP_FULLFILLED,
  GLOBAL_SIGN_UP_REJECT,
  GLOBAL_GET_STREAM_REQUEST,
  GLOBAL_GET_STREAM_FULTILLED,
  GLOBAL_GET_STREAM_REJECTED,
  GLOBAL_FORGOT,
  GLOBAL_FORGOT_REJECT,
  GLOBAL_FORGOT_SUCCESS,
  GLOBAL_GET_CHAT_FRASES_FULFILLED,
  GLOBAL_GET_CHAT_FRASES,
  GLOBAL_EDIT,
  GLOBAL_EDIT_REJECT,
  GLOBAL_EDIT_FULLFILLED,
  ILoggedInAction,
  ILoginAction,
  IEditAction,
  ILoginApi,
  ISignUpAction,
  IForgotAction,
  IForgotSuccessAction,
  IChatPhrase,
  ICodeAction,
  GLOBAL_LOGGED_IN_FULFILLED,
  GLOBAL_GET_AVATARS,
  GLOBAL_GET_AVATARS_FULFILLED,
  IAvatars,
  IEditedAction,
  GLOBAL_GET_PLAYER_ERROR,
  GLOBAL_GET_PLAYER,
  GLOBAL_EXCHANGE,
  GLOBAL_EXCHANGE_ERROR,
  GLOBAL_EXCHANGE_FULLFILLED,
  GLOBAL_VALIDATION,
  GLOBAL_VALIDATION_FULLFILLED,
  GLOBAL_VALIDATION_REJECT,
  GLOBAL_VALIDATION_RESEND,
  GLOBAL_VALIDATION_RESEND_FULLFILLED,
  GLOBAL_VALIDATION_RESEND_REJECT,
  ICodeResendAction,
  GLOBAL_LOGGED_IN_TRUE,
  GLOBAL_RESET,
  GLOBAL_LEVELS,
  GLOBAL_LEVELS_FULLFILLED,
  GLOBAL_LEVELS_ERROR,
  ILevels,
  GLOBAL_GET_BANNERS,
  IBanners,
  GLOBAL_GET_BANNERS_FULFILLED,
  IPlayerIndications,
  GLOBAL_GET_PLAYER_INDICATIONS,
  GLOBAL_GET_PLAYER_INDICATIONS_FULFILLED,
  GLOBAL_GET_PLAYER_INDICATIONS_ERROR,
  IGetPlayerIndicationsAction,
  IGetPlayerIndicationsService,
  IUpdatePhoneAction,
  GLOBAL_UPDATE_PHONE,
  IUpdatePhoneSuccess,
  GLOBAL_UPDATE_PHONE_SUCCESS,
  GLOBAL_UPDATE_PHONE_ERROR,
  IUpdatePhoneError,
  GLOBAL_GET_NOTIFICATIONS,
  GLOBAL_GET_NOTIFICATIONS_SUCCESS,
  INotifications,
  IGetNotifications,
  IGetNotificationsService,
  GLOBAL_RESET_NOTIFICATIONS,
  GLOBAL_GET_UNREAD_NOTIFICATIONS,
  GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS,
  GLOBAL_EDIT_RESET,
} from "./types";

export const globalValidationCodeResend = (player_id: number): ICodeResendAction => ({
  type: GLOBAL_VALIDATION_RESEND,
  payload: {
    player_id,
  },
});

export const globalValidationCodeResendError = (message: string): IReduxAction => ({
  type: GLOBAL_VALIDATION_RESEND_REJECT,
  payload: {
    message,
  },
});

export const globalValidationCodeResendFullfilled = (): IReduxAction => ({
  type: GLOBAL_VALIDATION_RESEND_FULLFILLED,
});

export const globalValidationCode = (code: string): ICodeAction => ({
  type: GLOBAL_VALIDATION,
  payload: {
    code,
  },
});

export const globalValidationCodeError = (message: string): IReduxAction => ({
  type: GLOBAL_VALIDATION_REJECT,
  payload: {
    message,
  },
});

export const globalValidationCodeFullfilled = (payload: ILoginApi): IReduxAction => ({
  type: GLOBAL_VALIDATION_FULLFILLED,
  payload,
});

export const globalExchange = (params: {}): IReduxAction => ({
  type: GLOBAL_EXCHANGE,
  payload: {
    ...params,
  },
});

export const globalExchangeFullFilled = (payload: ILoginApi): IReduxAction => ({
  type: GLOBAL_EXCHANGE_FULLFILLED,
  payload,
});

export const globalExchangeError = (message: string): IReduxAction => ({
  type: GLOBAL_EXCHANGE_ERROR,
  payload: {
    message,
  },
});

export const globalLogin = (email: string, password: string): ILoginAction => ({
  type: GLOBAL_LOGIN,
  payload: {
    email,
    password,
  },
});

export const globalForgot = (email: string): IForgotAction => ({
  type: GLOBAL_FORGOT,
  payload: {
    email,
  },
});

export const globalEdit = (params: {}): IEditAction => ({
  type: GLOBAL_EDIT,
  payload: {
    ...params,
  },
});

export const globalEditFullFilled = (payload: ILoginApi): IEditedAction => ({
  type: GLOBAL_EDIT_FULLFILLED,
  payload,
});

export const globalEditReject = (message: string): IReduxAction => ({
  type: GLOBAL_EDIT_REJECT,
  payload: {
    message,
  },
});

export const globalEditReset = (): IReduxAction => ({
  type: GLOBAL_EDIT_RESET,
});

export const globalLogout = (): IReduxAction => ({
  type: GLOBAL_LOGOUT,
});

export const globalLoggedIn = (payload: ILoginApi): ILoggedInAction => ({
  type: GLOBAL_LOGGED_IN,
  payload,
});

export const globalLoggedInFullField = (payload: ILoginApi): IReduxAction => ({
  type: GLOBAL_LOGGED_IN_FULFILLED,
  payload,
});

export const globalLoginReject = (message: string): IReduxAction => ({
  type: GLOBAL_LOGIN_REJECT,
  payload: {
    message,
  },
});

export const globalLoginInactive = (): IReduxAction => ({
  type: GLOBAL_LOGIN_INACTIVE,
});

export const globalForgotReject = (message: string): IReduxAction => ({
  type: GLOBAL_FORGOT_REJECT,
  payload: {
    message,
  },
});

export const globalForgotSuccess = (): IForgotSuccessAction => ({
  type: GLOBAL_FORGOT_SUCCESS,
});

export const globalToggleModal = (modalOpen: boolean): IReduxAction => ({
  type: GLOBAL_MODAL_TOGGLE,
  payload: {
    modalOpen,
  },
});

export const globalSetAuthForm = (authForm: string): IReduxAction => ({
  type: GLOBAL_SET_AUTH_FORM,
  payload: {
    authForm,
  },
});

export const globalSignUp = (
  full_name: string,
  nickname: string,
  email: string,
  password: string,
  password_confirmation: string,
  phone: string,
  birth_date: string,
  gender: string,
  accept: number,
  nickname_indication: string | null
): ISignUpAction => ({
  type: GLOBAL_SIGN_UP,
  payload: {
    full_name,
    nickname,
    email,
    password,
    password_confirmation,
    phone,
    birth_date,
    gender,
    accept,
    nickname_indication,
  },
});

export const globalSignUpReject = (message: string): IReduxAction => ({
  type: GLOBAL_SIGN_UP_REJECT,
  payload: {
    message,
  },
});

export const globalSignUpFullfilled = (payload: ILoginApi): IReduxAction => ({
  type: GLOBAL_SIGN_UP_FULLFILLED,
  payload,
});

export const globalGetAvatars = () => ({
  type: GLOBAL_GET_AVATARS,
});

export const globalGetAvatarsFulfilled = (payload: IAvatars[]) => ({
  type: GLOBAL_GET_AVATARS_FULFILLED,
  payload: {
    avatars: payload,
  },
});

export const globalGetNotifications = (payload: IGetNotificationsService): IGetNotifications => ({
  type: GLOBAL_GET_NOTIFICATIONS,
  payload,
});

export const globalGetUnreadNotifications = (): IReduxAction => ({
  type: GLOBAL_GET_UNREAD_NOTIFICATIONS,
});

export const globalGetUnreadNotificationsSucccess = (total_unread: number): IReduxAction => ({
  type: GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS,
  payload: {
    total_unread,
  },
});

export const globalResetNotifications = (): IReduxAction => ({
  type: GLOBAL_RESET_NOTIFICATIONS,
});

export const globalGetNotificationsSuccess = (payload: INotifications) => ({
  type: GLOBAL_GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const globalLevels = (): IReduxAction => ({
  type: GLOBAL_LEVELS,
});

export const globalLevelsFullfilled = (payload: ILevels[]): IReduxAction => ({
  type: GLOBAL_LEVELS_FULLFILLED,
  payload: {
    levels: payload,
  },
});

export const globalLevelsError = (message: string): IReduxAction => ({
  type: GLOBAL_LEVELS_ERROR,
  payload: {
    message,
  },
});

export const globalGetPhrasesChat = () => ({
  type: GLOBAL_GET_CHAT_FRASES,
});

export const globalChatPhrasesFulfilled = (chatPhrases: IChatPhrase[]) => ({
  type: GLOBAL_GET_CHAT_FRASES_FULFILLED,
  payload: {
    phrases: chatPhrases,
  },
});

export const globalGetStreamRequest = (): IReduxAction => ({
  type: GLOBAL_GET_STREAM_REQUEST,
});
export const globalGetStreamFulfilled = (type: string, content: string): IReduxAction => ({
  type: GLOBAL_GET_STREAM_FULTILLED,
  payload: {
    type,
    content,
  },
});
export const globalGetStreamRejected = (message: string): IReduxAction => ({
  type: GLOBAL_GET_STREAM_REJECTED,
  payload: {
    message,
  },
});

export const globalGetPlayer = (login = true): IReduxAction => {
  return {
    type: GLOBAL_GET_PLAYER,
    payload: { login },
  };
};

export const globalGetPlayerError = (error: any): IReduxAction => ({
  type: GLOBAL_GET_PLAYER_ERROR,
  payload: { error },
});

export const changeLoggedInTrue = (): IReduxAction => ({
  type: GLOBAL_LOGGED_IN_TRUE,
});

export const globalReset = (): IReduxAction => ({
  type: GLOBAL_RESET,
});

export const globalGetBanners = () => ({
  type: GLOBAL_GET_BANNERS,
});

export const globalGetBannersFulfilled = (banners: IBanners[]) => ({
  type: GLOBAL_GET_BANNERS_FULFILLED,
  payload: {
    banners,
  },
});

export const globalGetPlayerIndications = (
  dates?: IGetPlayerIndicationsService
): IGetPlayerIndicationsAction => ({
  type: GLOBAL_GET_PLAYER_INDICATIONS,
  payload: {
    ...dates,
  },
});

export const globalGetPlayerIndicationsFulfilled = (playerIndications: IPlayerIndications) => ({
  type: GLOBAL_GET_PLAYER_INDICATIONS_FULFILLED,
  payload: {
    playerIndications,
  },
});

export const globalGetPlayerIndicationsError = (error: string): IReduxAction => ({
  type: GLOBAL_GET_PLAYER_INDICATIONS_ERROR,
  payload: { error },
});

export const globalUpdatePhone = (phone: string): IUpdatePhoneAction => ({
  type: GLOBAL_UPDATE_PHONE,
  payload: {
    phone,
  },
});

export const globalUpdatePhoneSuccess = (phone: string): IUpdatePhoneSuccess => ({
  type: GLOBAL_UPDATE_PHONE_SUCCESS,
  payload: {
    phone,
  },
});

export const globalUpdatePhoneError = (error: string): IUpdatePhoneError => ({
  type: GLOBAL_UPDATE_PHONE_ERROR,
  payload: {
    error,
  },
});
