import { AxiosRequestConfig } from "axios";

import createApiInstance from "./ApiInstance";
import { API_URL } from "../config/constants";

const apiInstance = createApiInstance({ baseURL: API_URL });

export default class ApiClient {
  static get(endpont: string, config?: AxiosRequestConfig) {
    return apiInstance.get(endpont, config);
  }

  static post(endpont: string, body: object, config?: AxiosRequestConfig) {
    return apiInstance.post(endpont, body, {
      ...{ "Content-Type": "application/json; charset=utf-8" },
      ...(config || {}),
    });
  }

  static patch(endpont: string, body: object, config?: AxiosRequestConfig) {
    return apiInstance.patch(endpont, body, {
      ...{ "Content-Type": "application/json; charset=utf-8" },
      ...(config || {}),
    });
  }

  static postData(endpont: string, formData: FormData, config?: AxiosRequestConfig) {
    return apiInstance.post(endpont, formData, {
      ...{ "Content-Type": "multipart/form-data" },
      ...(config || {}),
    });
  }
}
