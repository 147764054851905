import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../state/ducks/types";
import { IAttributes } from "../../global";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";

import "./Card.scss";
import { checkGoodOne } from "../../utils/Functions";
import {
  EnumGameStatus,
  EnumPlayerCardStatus,
  IBingoGame,
  IWinner,
} from "../../state/ducks/games/types";
import { setBallScreen } from "../../state/ducks/gameCards/actions";
import {
  setWinnerCorner,
  setWinnerBallScreen,
  setWinnerFull,
  setWinnerLine,
  setLoserCorner,
  setLoserLine,
  setLoserFull,
  setLoserBallScreen,
} from "../../state/ducks/games/actions";

export interface ICardProps extends IAttributes {
  id: number;
  numbers?: (number | null)[][];
  statusFull?: EnumPlayerCardStatus;
  statusBallScreen?: EnumPlayerCardStatus;
  statusLine?: EnumPlayerCardStatus;
  statusCorner?: EnumPlayerCardStatus;
  roundId?: number;
  prize?: number;
  roomId?: number;
  ballScreen?: number;
  lineBingo?: number[] | null;
  cornerBingo?: number[] | null;
  ballsDrawn: number[];
  fastBingo?: boolean;
  gameCode: "bngdog" | "bngcat" | "fastbingo";
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Card: FC<ICardProps> = ({
  id,
  numbers,
  statusFull,
  statusBallScreen,
  statusLine,
  statusCorner,
  children,
  prize,
  roomId,
  ballScreen,
  lineBingo,
  cornerBingo,
  roundId,
  ballsDrawn,
  fastBingo = false,
  gameCode,
}) => {
  const dispatch = useDispatch();

  const { playerCards } = useSelector((state: IRootState) => state.gameCards);

  const {
    setBallScreen: { loading },
  } = useSelector((state: IRootState) => state.gameCards);

  const { bingoGame: _bingoGame, winners } = useSelector((state: IRootState) => state.games);

  const [bingoGame, setBingoGame] = useState<IBingoGame | null>(null);

  useEffect(() => {
    if (_bingoGame && gameCode) {
      setBingoGame(_bingoGame[gameCode]);
    }
  }, [_bingoGame, gameCode, fastBingo]);

  const openGame = bingoGame && bingoGame.status === EnumGameStatus.OPENED;

  const goodOne =
    checkGoodOne(ballsDrawn, numbers, statusLine, statusCorner) &&
    (statusFull === EnumPlayerCardStatus.ACTIVE || statusLine === EnumPlayerCardStatus.ACTIVE) &&
    !fastBingo;

  const activeStyle =
    goodOne && statusFull !== EnumPlayerCardStatus.LOSER
      ? "good-one"
      : statusFull === EnumPlayerCardStatus.ACTIVE
      ? EnumPlayerCardStatus.ACTIVE
      : "";

  const oldStatusFull = usePrevious(statusFull);
  const oldStatusLine = usePrevious(statusLine);
  const oldStatusCorner = usePrevious(statusCorner);
  const oldStatusBallScreen = usePrevious(statusBallScreen);

  const handleNumberClick = useCallback(
    (cardId, number, statusBallScreen) => {
      if (!openGame || statusBallScreen === EnumPlayerCardStatus.INACTIVE || !number) return;
      setBallScreenLocal({
        cardId,
        ballScreen: number,
      });
      dispatch(setBallScreen(cardId, number));
    },
    [openGame, dispatch]
  );

  useEffect(() => {
    const winnersRound = winners?.find(
      (w) => w.round_id.toString() === bingoGame?.round_id.toString()
    );
    const winnersRoom = winnersRound?.winners?.find(
      (w) => w.bingo_game_room_id.toString() === roomId?.toString()
    );
    const winnersBallScreen =
      winnersRoom?.ball_screen.filter((item: IWinner) => {
        return item.status_ball_screen === "WINNER";
      }) ?? [];
    const winnersLine =
      winnersRoom?.line.filter((item: IWinner) => {
        return item.status_line === "WINNER";
      }) ?? [];

    const winnersCorner =
      winnersRoom?.corner.filter((item: IWinner) => {
        return item.status_corner === "WINNER";
      }) ?? [];

    const winnersFull =
      winnersRoom?.full.filter((item: IWinner) => {
        return item.status_full === "WINNER";
      }) ?? [];

    if (oldStatusCorner && oldStatusCorner !== statusCorner) {
      if (statusCorner === EnumPlayerCardStatus.WINNER) {
        dispatch(setWinnerCorner());
      }
      if (statusCorner === EnumPlayerCardStatus.LOSER) {
        if (
          !playerCards.find(
            (card) =>
              card.bingo_game_room_id === roomId &&
              card.status_corner === EnumPlayerCardStatus.WINNER
          )
        ) {
          dispatch(setLoserCorner(winnersCorner));
        }
      }
    }
    if (oldStatusLine && oldStatusLine !== statusLine) {
      if (statusLine === EnumPlayerCardStatus.WINNER) {
        dispatch(setWinnerLine());
      }
      if (statusLine === EnumPlayerCardStatus.LOSER) {
        if (
          !playerCards.find(
            (card) =>
              card.bingo_game_room_id === roomId && card.status_line === EnumPlayerCardStatus.WINNER
          )
        ) {
          dispatch(setLoserLine(winnersLine));
        }
      }
    }
    if (oldStatusFull && oldStatusFull !== statusFull) {
      if (statusFull === EnumPlayerCardStatus.WINNER) {
        dispatch(setWinnerFull());
      }
      if (statusFull === EnumPlayerCardStatus.LOSER) {
        if (
          !playerCards.find(
            (card) =>
              card.bingo_game_room_id === roomId && card.status_full === EnumPlayerCardStatus.WINNER
          )
        ) {
          dispatch(setLoserFull(winnersFull));
        }
      }
    }
    if (oldStatusBallScreen && oldStatusBallScreen !== statusBallScreen) {
      if (statusBallScreen === EnumPlayerCardStatus.WINNER) {
        dispatch(setWinnerBallScreen());
      }
      if (statusBallScreen === EnumPlayerCardStatus.LOSER) {
        if (
          !playerCards.find(
            (card) =>
              card.bingo_game_room_id === roomId &&
              card.status_ball_screen === EnumPlayerCardStatus.WINNER
          )
        ) {
          dispatch(setLoserBallScreen(winnersBallScreen));
        }
      }
    }
  }, [
    statusCorner,
    oldStatusCorner,
    statusLine,
    oldStatusLine,
    statusFull,
    oldStatusFull,
    statusBallScreen,
    oldStatusBallScreen,
    playerCards,
    roomId,
    dispatch,
    winners,
    bingoGame,
  ]);

  const [ballScreenLocal, setBallScreenLocal] = useState<{ cardId: number; ballScreen: number }>();

  useEffect(() => {
    if (loading === false) {
      setBallScreenLocal({
        ballScreen: 0,
        cardId: 0,
      });
    }
  }, [loading]);

  return (
    <div className={`card ${statusFull?.toLowerCase()}`}>
      <p className={`card bingo-label ${statusFull?.toLowerCase()}`}>
        <span className="text">BINGO</span>
        {children && <span>{children}</span>}
        {(statusFull === EnumPlayerCardStatus.WINNER ||
          oldStatusFull === EnumPlayerCardStatus.WINNER) && <span>GANHOU!</span>}
        {((statusFull === EnumPlayerCardStatus.LOSER &&
          (statusLine === EnumPlayerCardStatus.LOSER ||
            statusLine === EnumPlayerCardStatus.INACTIVE) &&
          (statusCorner === EnumPlayerCardStatus.LOSER ||
            statusCorner === EnumPlayerCardStatus.INACTIVE) &&
          (statusBallScreen === EnumPlayerCardStatus.LOSER ||
            statusBallScreen === EnumPlayerCardStatus.INACTIVE)) ||
          (oldStatusFull === EnumPlayerCardStatus.LOSER &&
            (oldStatusLine === EnumPlayerCardStatus.LOSER ||
              oldStatusLine === EnumPlayerCardStatus.INACTIVE) &&
            (oldStatusCorner === EnumPlayerCardStatus.LOSER ||
              oldStatusCorner === EnumPlayerCardStatus.INACTIVE) &&
            (oldStatusBallScreen === EnumPlayerCardStatus.LOSER ||
              oldStatusBallScreen === EnumPlayerCardStatus.INACTIVE))) && <span>PERDEU</span>}
        <span className="icon">
          <img src="/cifrao.png" alt="" width={21} />
          {prize && prize > 1 ? Math.trunc(prize) : prize?.toString().replace(".", ",")}
        </span>
      </p>
      <div className={`card__table card ${statusFull?.toLowerCase()}`}>
        {numbers?.map((row, kCol) =>
          row.map((n, kRow) => (
            <div
              className={classnames("card__table__number", {
                [`${activeStyle.toLowerCase()}`]:
                  n && ballsDrawn?.includes(n) && statusFull !== EnumPlayerCardStatus.LOSER,
                "winner-line":
                  n && lineBingo?.includes(n) && statusLine === EnumPlayerCardStatus.WINNER,
                "winner-corner":
                  n && cornerBingo?.includes(n) && statusCorner === EnumPlayerCardStatus.WINNER,
                "ball-screen":
                  n && n === ballScreen && statusBallScreen === EnumPlayerCardStatus.ACTIVE,
                "ball-screen winner":
                  n === ballScreen && statusBallScreen === EnumPlayerCardStatus.WINNER,
                "click-ball-screen":
                  n && openGame && statusBallScreen === EnumPlayerCardStatus.ACTIVE,
              })}
              key={`card_${kCol}_${kRow}`}
              onClick={() => handleNumberClick(id, n, statusBallScreen)}
            >
              {loading && ballScreenLocal?.ballScreen === n && ballScreenLocal.cardId === id ? (
                <Spinner />
              ) : (
                <span>{n}</span>
              )}
            </div>
          ))
        )}
      </div>
      <p className="card bingo-label-foot">
        <Fragment>
          {roundId && <span>R: {roundId} </span>}
          {roomId && <span>S: {roomId}</span>}
        </Fragment>
      </p>
    </div>
  );
};

export default Card;
