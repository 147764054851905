import React from "react";
import { IAttributes } from "../../../global";

import { EnumGameStatus } from "../../../state/ducks/games/types";

import "./BallsListSections.scss";

import BallsList from "../BallsList";

interface IBallsListSections extends IAttributes {
  gameStatus: string | undefined;
  playerOnGame: boolean | undefined;
  cards: number;
  audioBalls?: HTMLAudioElement[];
  balls: number[];
}

const BallsListSections: React.FC<IBallsListSections> = ({
  gameStatus,
  playerOnGame,
  cards,
  audioBalls,
  balls,
}) => {
  if (
    (gameStatus === EnumGameStatus.STARTED || gameStatus === EnumGameStatus.FINISHED) &&
    ((playerOnGame && cards > 0) || cards === 0)
  ) {
    return (
      <BallsList
        audioBalls={audioBalls}
        ballsDrawn={balls?.slice(0, 8)}
        lastBall={Number(balls?.slice(0, 1).join())}
        ballsDrawnCount={balls.length}
      />
    );
  } else {
    return <></>;
  }
};

export default BallsListSections;
