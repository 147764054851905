import { REHYDRATE } from "redux-persist";
import { takeLatest, all, call, put } from "redux-saga/effects";

import Cookie, { ACCESS_COOKIE } from "../../../services/Cookie";
import { initialize } from "../webSocket/actions";
import {
  globalChatPhrasesFulfilled,
  globalForgotReject,
  globalForgotSuccess,
  globalGetStreamFulfilled,
  globalGetStreamRejected,
  globalLoggedIn,
  globalLoginInactive,
  globalLoginReject,
  globalEditFullFilled,
  globalEditReject,
  globalGetAvatarsFulfilled,
  globalGetPlayerError,
  globalExchangeError,
  globalExchangeFullFilled,
  globalValidationCodeError,
  globalValidationCodeFullfilled,
  globalValidationCodeResendError,
  globalValidationCodeResendFullfilled /*
  globalSignUpReject,
  globalSignUpFullfilled,
  /** */,
  /** By-pass SMS validation */ globalLevelsFullfilled,
  globalLevelsError,
  changeLoggedInTrue,
  globalLoggedInFullField,
  globalGetBannersFulfilled,
  globalGetPlayerIndicationsError,
  globalGetPlayerIndicationsFulfilled,
  globalUpdatePhoneSuccess,
  globalUpdatePhoneError,
  globalGetNotificationsSuccess,
  globalGetUnreadNotificationsSucccess,
  globalEditReset,
} from "./actions";

import Global, { postLogout } from "./api";
import {
  GLOBAL_LOGGED_IN,
  GLOBAL_LOGIN,
  GLOBAL_LOGOUT,
  GLOBAL_SIGN_UP,
  GLOBAL_GET_STREAM_REQUEST,
  GLOBAL_FORGOT,
  GLOBAL_EDIT,
  GLOBAL_GET_CHAT_FRASES,
  GLOBAL_GET_AVATARS,
  GLOBAL_EXCHANGE,
  ILoginAction,
  IEditAction,
  ISignUpAction,
  IForgotAction,
  GLOBAL_GET_PLAYER,
  GLOBAL_VALIDATION,
  GLOBAL_VALIDATION_RESEND,
  ICodeAction,
  ICodeResendAction,
  GLOBAL_LEVELS,
  ILevels,
  GLOBAL_GET_BANNERS,
  GLOBAL_GET_PLAYER_INDICATIONS,
  IGetPlayerIndicationsAction,
  EnumPlayerStatus,
  GLOBAL_UPDATE_PHONE,
  IUpdatePhoneAction,
  GLOBAL_GET_NOTIFICATIONS,
  IGetNotifications,
  GLOBAL_GET_UNREAD_NOTIFICATIONS,
} from "./types";

export function* globalSaga() {
  yield all([
    yield takeLatest(GLOBAL_LOGIN, login),
    yield takeLatest(GLOBAL_SIGN_UP, signUp),
    yield takeLatest(GLOBAL_EXCHANGE, exchange),
    //yield takeLatest(REHYDRATE, loggedIn),
    yield takeLatest(GLOBAL_LOGGED_IN, loggedIn),
    yield takeLatest(GLOBAL_LOGOUT, logout),
    yield takeLatest(GLOBAL_FORGOT, forgot),
    yield takeLatest(GLOBAL_GET_CHAT_FRASES, getChatPhrases),
    yield takeLatest(GLOBAL_GET_BANNERS, getBannerWorker),
    yield takeLatest(GLOBAL_GET_AVATARS, getAvatars),
    yield takeLatest(GLOBAL_GET_NOTIFICATIONS, getNotifications),
    yield takeLatest(GLOBAL_GET_UNREAD_NOTIFICATIONS, getUnreadNotifications),
    yield takeLatest(GLOBAL_GET_STREAM_REQUEST, getStream),
    yield takeLatest(GLOBAL_EDIT, edit),
    yield takeLatest(GLOBAL_GET_PLAYER, getPlayerWorker),
    yield takeLatest(GLOBAL_VALIDATION, validationSms),
    yield takeLatest(GLOBAL_VALIDATION_RESEND, validateSmsResend),
    yield takeLatest(GLOBAL_LEVELS, getLevels),
    yield takeLatest(GLOBAL_GET_PLAYER_INDICATIONS, getPlayerIndicationsWorker),
    yield takeLatest(GLOBAL_UPDATE_PHONE, updatePhoneWorker),
    yield takeLatest(REHYDRATE, persistRehydrate),
  ]);
}

function* validationSms({ payload }: ICodeAction) {
  try {
    const data = yield call(Global.validationSmsCode, payload);
    Cookie.saveAuthenticationCookie(data?.token?.token, data?.token?.type, data?.token?.expires_at);
    delete data?.token?.token;
    delete data?.token?.type;
    delete data?.token?.expires_at;
    yield put(globalValidationCodeFullfilled(data));
    yield put(changeLoggedInTrue());
  } catch (err) {
    yield put(globalValidationCodeError(err));
  }
}

function* validateSmsResend({ payload }: ICodeResendAction) {
  try {
    yield call(Global.validationSmsCodeResend, payload);
    yield put(globalValidationCodeResendFullfilled());
  } catch (err) {
    yield put(globalValidationCodeResendError(err));
  }
}

function* exchange({ payload }: any) {
  try {
    const data = yield call(Global.exchangeTrade, payload);
    yield put(globalExchangeFullFilled(data));
  } catch (error) {
    yield put(globalExchangeError(error));
  }
}

function* login({ payload }: ILoginAction) {
  try {
    const data = yield call(Global.login, payload);
    Cookie.saveAuthenticationCookie(data?.token?.token, data?.token?.type, data?.token?.expires_at);
    delete data?.token?.token;
    delete data?.token?.type;
    delete data?.token?.expires_at;
    yield put(globalLoggedIn(data));
    if (data?.player?.status === EnumPlayerStatus.ACTIVE) {
      yield put(changeLoggedInTrue());
    } else {
      yield put(globalLoginInactive());
    }
  } catch (error) {
    yield put(globalLoginReject(error));
  }
}

function* edit({ payload }: IEditAction) {
  try {
    const data = yield call(Global.edit, payload);
    yield put(globalEditFullFilled(data));
  } catch (error) {
    yield put(globalEditReject(error));
  } finally {
    yield put(globalEditReset());
  }
}

function* forgot({ payload }: IForgotAction) {
  try {
    yield call(Global.forgot, payload);
    yield put(globalForgotSuccess());
  } catch (error) {
    yield put(globalForgotReject(error));
  }
}

/** By-pass SMS validation */ /*
function* signUp({ payload }: ISignUpAction) {
  try {
    const data = yield call(Global.signUp, payload);
    yield put(globalSignUpFullfilled(data));
  } catch (error) {
    console.error("login error", error);
    yield put(globalSignUpReject(error));
  }
}
*/

function* signUp({ payload }: ISignUpAction) {
  try {
    const data = yield call(Global.signUp, payload);
    Cookie.saveAuthenticationCookie(data?.token?.token, data?.token?.type, data?.token?.expires_at);
    delete data?.token?.token;
    delete data?.token?.refreshToken;
    yield put(globalLoggedIn(data));
  } catch (error) {
    console.error("login error", error);
    yield put(globalLoginReject(error));
  }
}

function* loggedIn() {
  //console.log("loggedIn");
  try {
    const cookieAuth = Cookie.getAuthenticationCookie();
    if (cookieAuth) {
      //console.log("initialize");
      yield put(initialize());
    }
  } catch (error: any) {
    yield put(globalLoginReject(error.message));
  }
}

function* logout() {
  try {
    yield call(postLogout);
    Cookie.deleteCookie(ACCESS_COOKIE);
  } catch (error) {
    console.log("error", error);
  }
}

function* getAvatars() {
  try {
    const data = yield call(Global.getAvatars);
    yield put(globalGetAvatarsFulfilled(data));
  } catch (error) {
    console.log("get avatars error", error);
  }
}

function* getNotifications({ payload }: IGetNotifications) {
  try {
    const data = yield call(Global.getNotifications, payload);
    yield put(globalGetNotificationsSuccess(data));
  } catch (error) {
    console.log("get notifications error", error);
  }
}

function* getUnreadNotifications() {
  try {
    const data = yield call(Global.getUnreadNotifications);
    yield put(globalGetUnreadNotificationsSucccess(data.total_unread));
  } catch (error) {
    console.log("get notifications error", error);
  }
}

function* getLevels() {
  try {
    const data: ILevels[] = yield call(Global.getLevels);
    yield put(globalLevelsFullfilled(data));
  } catch (error) {
    yield put(globalLevelsError(error));
  }
}

function* getChatPhrases() {
  try {
    const data = yield call(Global.getChatPhrases);
    yield put(globalChatPhrasesFulfilled(data));
  } catch (error) {
    console.error("login error", error);
  }
}

function* getStream() {
  try {
    const { data } = yield call(Global.getStream);

    const content =
      data.tipo === "twitch"
        ? data.conteudo.replace("parent=twitch.tv", `parent=${global.location.hostname}`)
        : data.conteudo;

    yield put(globalGetStreamFulfilled(data.tipo, content));
  } catch (error) {
    console.error("getStream", error);
    yield put(
      globalGetStreamRejected(
        error?.response?.data?.status?.message ?? "Algo saiu errado! Tente novamente mais tarde"
      )
    );
  }
}

function* getPlayerWorker({ payload }: any) {
  try {
    const data = yield call(Global.getPlayer);
    console.log("getPlayerWorker", data);
    if (payload?.login) {
      yield put(globalLoggedIn(data));
    } else {
      yield put(globalLoggedInFullField(data));
    }
    if (data?.player?.status !== EnumPlayerStatus.ACTIVE) {
      yield put(globalLoginInactive());
    }
  } catch (error) {
    yield put(globalGetPlayerError(error));
  }
}

function* getBannerWorker() {
  try {
    const data = yield call(Global.getBanners);
    yield put(globalGetBannersFulfilled(data));
  } catch (error) {
    console.error("login error", error);
  }
}

function* getPlayerIndicationsWorker({ payload }: IGetPlayerIndicationsAction) {
  try {
    const data = yield call(Global.getPlayerIndications, payload);
    yield put(globalGetPlayerIndicationsFulfilled(data));
  } catch (error) {
    yield put(globalGetPlayerIndicationsError(error));
    console.error("login error", error);
  }
}

function persistRehydrate({ payload }: any) {
  const cookieAuth = Cookie.getAuthenticationCookie();
  const cookieReauth = Cookie.getReAuthenticationCookie();
  if (!cookieAuth && cookieReauth && payload?.loggedIn) {
    localStorage.clear();
    window.location.reload();
  }
}

function* updatePhoneWorker({ payload }: IUpdatePhoneAction) {
  try {
    yield call(Global.updatePhone, payload);
    yield put(globalUpdatePhoneSuccess(payload?.phone));
  } catch (error) {
    yield put(globalUpdatePhoneError(error));
  }
}
