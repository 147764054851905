import React from "react";
import { IAttributes } from "../../../global";
import { EnumGameStatus } from "../../../state/ducks/games/types";

import "./WaitNextGame.scss";

export interface IWaitNextGame extends IAttributes {
  gameStatus: string | undefined;
  totalCards: number;
}

const WaitNextGame: React.FC<IWaitNextGame> = ({ gameStatus, totalCards }) => {
  return (
    <React.Fragment>
      {gameStatus === EnumGameStatus.STARTED && totalCards === 0 && (
        <div className="box-prix">
          <h3>JOGO INICIADO</h3>
          <br />
          <br />
          <h4>
            AGUARDE A <br /> PRÓXIMA RODADA
          </h4>
        </div>
      )}
    </React.Fragment>
  );
};

export default WaitNextGame;
