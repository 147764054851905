import classNames from "classnames";
import React, { useState } from "react";
import { IAttributes } from "../../global";
import "./ComoJogar.scss";

export interface IComoJogar extends IAttributes {
  color: string;
}

const ComoJogar: React.FC<IComoJogar> = ({ color }) => {
  const [showComoJogar, setShowComoJogar] = useState<boolean>(false);

  const handleShowComoJogar = () => {
    setShowComoJogar(!showComoJogar);
  };

  const comoJogarBox = () => {
    return (
      <div className="wComoJogar">
        <div className={classNames("content", { active: showComoJogar })}>
          <span className="close" onClick={() => handleShowComoJogar()}></span>
          <div className="text">
            <p>
              <strong>Como jogar:</strong>
              <br />
            </p>
            <p>Espere terminar o sorteio que está acontecendo para poder comprar cartelas.</p>
            <p>
              Ao terminar o sorteio clique em comprar cartelas, escolha qual prêmio quer concorrer e
              clique na setinha, adicionando a quantidade de cartelas para jogar. Lembrando que são
              permitidas no máximo 4 cartelas por jogo. Após escolher a quantidade de cartelas,
              clique em jogar.
            </p>
            <p>
              Não é preciso marcar o sorteio. Tudo é feito de forma automática e você só precisa
              acompanhar o sorteio e torcer pelos números de sua cartela!
            </p>
            <p>
              <strong>Prêmios:</strong>
            </p>
            <p>Você tem 4 chances de ganhar em cada sorteio!</p>
            <p>
              <strong>- 1º bola na tela:</strong>
            </p>
            <p>
              - Antes de começar o sorteio, clique em cima de um número em cada cartela. Se esse
              número que você escolheu for o primeiro a ser sorteado você ganha 5% do valor do
              prêmio que está concorrendo!
            </p>
            <p>
              <strong>- 4 Cantos:</strong>
            </p>
            <p>
              Quem fizer os 4 cantos da cartela primeiro ganha 10% do valor do prêmios que está
              concorrendo!
            </p>
            <p>
              <strong>- Linha:</strong>
            </p>
            <p>
              Quem fizer a linha primeiro, pode ser na vertical ou horizontal ganha 10% do valor que
              está concorrendo!
            </p>
            <p>
              <strong>- Cartela cheia:</strong>
            </p>
            <p>O jogador que preencher a cartela primeiro leva o prêmio de cartela cheia!</p>
            <p>*Caso haja mais de 1 ganhador nos prêmios, o valor é dividido.</p>
            <p>
              Para conferir os últimos ganhadores, só clicar em últimos ganhadores na tela do jogo.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="buttonComoJogar" onClick={() => handleShowComoJogar()}>
        <h3 style={{ color }}>COMO JOGAR</h3>
      </div>
      {comoJogarBox()}
    </>
  );
};

export default ComoJogar;
