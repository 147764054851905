export const GLOBAL_LOGIN = "GLOBAL_LOGIN";
export const GLOBAL_LOGGED_IN = "GLOBAL_LOGGED_IN";
export const GLOBAL_LOGIN_REJECT = "GLOBAL_LOGIN_REJECT";
export const GLOBAL_LOGGED_IN_FULFILLED = "GLOBAL_LOGGED_IN_FULFILLED";
export const GLOBAL_LOGIN_INACTIVE = "GLOBAL_LOGIN_INACTIVE";

export const GLOBAL_LOGGED_IN_TRUE = "GLOBAL_LOGGED_IN_TRUE";

export const GLOBAL_VALIDATION = "GLOBAL_VALIDATION";
export const GLOBAL_VALIDATION_REJECT = "GLOBAL_VALIDATION_REJECT";
export const GLOBAL_VALIDATION_FULLFILLED = "GLOBAL_VALIDATION_FULLFILLED";

export const GLOBAL_VALIDATION_RESEND = "GLOBAL_VALIDATION_RESEND";
export const GLOBAL_VALIDATION_RESEND_REJECT = "GLOBAL_VALIDATION_RESEND_REJECT";
export const GLOBAL_VALIDATION_RESEND_FULLFILLED = "GLOBAL_VALIDATION_RESEND_FULLFILLED";

export const GLOBAL_FORGOT = "GLOBAL_FORGOT";
export const GLOBAL_FORGOT_REJECT = "GLOBAL_FORGOT_REJECT";
export const GLOBAL_FORGOT_SUCCESS = "GLOBAL_FORGOT_SUCCESS";

export const GLOBAL_UPDATE_PHONE = "GLOBAL_UPDATE_PHONE";
export const GLOBAL_UPDATE_PHONE_ERROR = "GLOBAL_UPDATE_PHONE_ERROR";
export const GLOBAL_UPDATE_PHONE_SUCCESS = "GLOBAL_UPDATE_PHONE_SUCCESS";

export const GLOBAL_LOGOUT = "GLOBAL_LOGOUT";

export const GLOBAL_MODAL_TOGGLE = "GLOBAL_MODAL_TOGGLE";

export const GLOBAL_SET_AUTH_FORM = "GLOBAL_SET_AUTH_FORM";

export const GLOBAL_RESET = "GLOBAL_RESET";

export const GLOBAL_SIGN_UP = "GLOBAL_SIGN_UP";
export const GLOBAL_SIGN_UP_REJECT = "GLOBAL_SIGN_UP_REJECT";
export const GLOBAL_SIGN_UP_FULLFILLED = "GLOBAL_SIGN_UP_FULLFILLED";

export const GLOBAL_EDIT = "GLOBAL_EDIT";
export const GLOBAL_EDIT_REJECT = "GLOBAL_EDIT_REJECT";
export const GLOBAL_EDIT_FULLFILLED = "GLOBAL_EDIT_FULLFILLED";
export const GLOBAL_EDIT_RESET = "GLOBAL_EDIT_RESET";

export const GLOBAL_GET_CHAT_FRASES = "GLOBAL_GET_CHAT_FRASES";
export const GLOBAL_GET_CHAT_FRASES_FULFILLED = "GLOBAL_GET_CHAT_FRASES_FULFILLED";

export const GLOBAL_GET_STREAM_REQUEST = "GLOBAL_GET_STREAM_REQUEST";
export const GLOBAL_GET_STREAM_FULTILLED = "GLOBAL_GET_STREAM_FULTILLED";
export const GLOBAL_GET_STREAM_REJECTED = "GLOBAL_GET_STREAM_REJECTED";

export const GLOBAL_GET_AVATARS = "GLOBAL_GET_AVATARS";
export const GLOBAL_GET_AVATARS_FULFILLED = "GLOBAL_GET_AVATARS_FULFILLED";

export const GLOBAL_GET_PLAYER = "GLOBAL_GET_PLAYER";
export const GLOBAL_GET_PLAYER_FULFILLED = "GLOBAL_GET_PLAYER_FULFILLED";
export const GLOBAL_GET_PLAYER_ERROR = "GLOBAL_GET_PLAYER_ERROR";

export const GLOBAL_GET_NOTIFICATIONS = "GLOBAL_GET_NOTIFICATIONS";
export const GLOBAL_RESET_NOTIFICATIONS = "GLOBAL_RESET_NOTIFICATIONS";
export const GLOBAL_GET_NOTIFICATIONS_ERROR = "GLOBAL_GET_NOTIFICATIONS_ERROR";
export const GLOBAL_GET_NOTIFICATIONS_SUCCESS = "GLOBAL_GET_NOTIFICATIONS_SUCCESS";
export const GLOBAL_GET_UNREAD_NOTIFICATIONS = "GLOBAL_GET_UNREAD_NOTIFICATIONS";
export const GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS = "GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS";

export const GLOBAL_EXCHANGE = "GLOBAL_EXCHANGE";
export const GLOBAL_EXCHANGE_ERROR = "GLOBAL_EXCHANGE_ERROR";
export const GLOBAL_EXCHANGE_FULLFILLED = "GLOBAL_EXCHANGE_FULLFILLED";

export const CADASTRO_INDICACAO = "CADASTRO_INDICACAO";

export const TIMEOUT_LIMIT = "TIMEOUT_LIMIT";
export const TIMEOUT_LIMIT_NUMBER = 7200;

export const INSTAGRAM_URL = "https://www.instagram.com/bingotine/";
export const FACEBOOK_URL = "https://www.facebook.com/Bingotine/";

export const GLOBAL_LEVELS = "GLOBAL_LEVELS";
export const GLOBAL_LEVELS_FULLFILLED = "GLOBAL_LEVELS_FULLFILLED";
export const GLOBAL_LEVELS_ERROR = "GLOBAL_LEVELS_ERROR";

export const GLOBAL_GET_BANNERS = "GLOBAL_GET_BANNERS";
export const GLOBAL_GET_BANNERS_FULFILLED = "GLOBAL_GET_BANNERS_FULFILLED";

export const GLOBAL_GET_PLAYER_INDICATIONS = "GLOBAL_GET_PLAYER_INDICATIONS";
export const GLOBAL_GET_PLAYER_INDICATIONS_FULFILLED = "GLOBAL_GET_BANNER_FULFILLED";
export const GLOBAL_GET_PLAYER_INDICATIONS_ERROR = "GLOBAL_GET_PLAYER_INDICATIONS_ERROR";

export interface IStream {
  type: EnumStreamTypes | undefined;
  content: string | undefined;
  error: string | undefined;
  isLoading: boolean;
}

export interface IForgot {
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
}

export interface IGlobalState {
  login: string;
  sending: boolean;
  success: boolean;
  loggedIn: boolean;
  finishedLoggeInCheck: boolean;
  savedPlayer: boolean;
  cliente: ILoginCliente;
  player: ILoginPlayer;
  error: string;
  modalOpen: boolean;
  authForm: "login" | "cadastro" | "forgot" | "cadastroRepresentante";
  stream: IStream;
  forgot: IForgot;
  chat: IChat;
  avatars: IAvatars[];
  notifications: INotifications;
  smsState: EnumSmsType;
  smsControlForm: EnumControlSmsFormType;
  exchangeSuccess: boolean;
  levels: ILevels[];
  banners: IBanners[];
  playerIndications: IPlayerIndications | null;
  updatePhone: {
    error?: string;
    success: boolean;
    loading: boolean;
  };
}

export interface ILoginAction {
  type: typeof GLOBAL_LOGIN;
  payload: ILoginService;
}

export interface IGetPlayerIndicationsAction {
  type: typeof GLOBAL_GET_PLAYER_INDICATIONS;
  payload: IGetPlayerIndicationsService;
}

export interface IUpdatePhoneSuccess {
  type: typeof GLOBAL_UPDATE_PHONE_SUCCESS;
  payload: {
    phone: string;
  };
}

export interface IUpdatePhoneError {
  type: typeof GLOBAL_UPDATE_PHONE_ERROR;
  payload: {
    error: string;
  };
}

export interface IUpdatePhoneAction {
  type: typeof GLOBAL_UPDATE_PHONE;
  payload: IUpdatePhoneService;
}

export interface IForgotAction {
  type: typeof GLOBAL_FORGOT;
  payload: IForgotService;
}

export interface ISignUpAction {
  type: typeof GLOBAL_SIGN_UP;
  payload: ISignUpService;
}

export interface IEditAction {
  type: typeof GLOBAL_EDIT;
  payload: IEditService;
}

export interface ILoggedInAction {
  type: typeof GLOBAL_LOGGED_IN;
  payload: ILoginApi;
}

export interface IEditedAction {
  type: typeof GLOBAL_EDIT_FULLFILLED;
  payload: ILoginApi;
}

export interface IForgotSuccessAction {
  type: typeof GLOBAL_FORGOT_SUCCESS;
}

export interface ILoginService {
  email: string;
  password: string;
}

export interface IForgotService {
  email: string;
}

export interface IEditService {
  [key: string]: any;
}

export interface IUpdatePhoneService {
  phone: string;
}

export interface ICodeAction {
  type: typeof GLOBAL_VALIDATION;
  payload: ICodeService;
}

export interface ICodeService {
  code: string;
}

export interface ICodeResendAction {
  type: typeof GLOBAL_VALIDATION_RESEND;
  payload: ICodeResendService;
}

export interface ICodeResendService {
  player_id: number;
}

export interface ISignUpService {
  full_name: string;
  nickname: string;
  email: string;
  password: string;
  password_confirmation: string;
  accept: number;
  phone: string;
  birth_date: string;
  gender: string;
  nickname_indication?: string | null;
}

export interface ILoginPlayer {
  id: number;
  full_name: string;
  nickname: string;
  email: string;
  phone: string;
  birth_date: string;
  gender: string;
  status: string;
  group: string;
  avatar_id: number;
  bets_total?: number;
  drawable_balance: number;
  comission_balance: number;
  avatar?: IAvatar;
  accounts: IAccount[];
  level: ILevel;
  last_date_deposit?: string;
}

export interface ILevel {
  id: number;
  name: string;
  color: string;
  image: string;
  bets: number;
  amount: number;
  value: number;
  image_url: string;
}

export interface IAccount {
  id: number;
  balance: number;
  accountType: IAccountType;
}

export interface IAccountType {
  id: number;
  description: string;
  code: string;
}

export interface ILoginCliente {
  id: number;
  nome: string;
  sobrenome?: string;
  email?: string;
  avatar?: IAvatar;
  apelido: string;
  grupo: number;
  casinoApostas: number;
  statusRepresentante: string;
  faixasPremios: PremiosFaixa[];
}

export interface IAvatar {
  [key: string]: {
    id: number;
    description: string;
    image: string;
    free?: boolean;
    price?: "";
    gender?: string;
    image_url?: string;
  };
}

export interface ILoginApi {
  player: ILoginPlayer;
}

export interface ISignUpApi {
  [key: string]: any;
}

export interface IEditApi {
  [key: string]: any;
}

export interface IExchangeApi {
  [key: string]: any;
}

export interface IGetPlayerIndicationsService {
  dateFrom?: string;
  dateTo?: string;
}

export interface IGetPlayerIndicationsApi {
  playerIndications: IPlayerIndications;
}

export interface ICodeApi {
  [key: string]: any;
}
export interface ITransferenciaApi {
  id: number;
  valor: number;
  status: string;
}

export interface IStreamContent {
  type: EnumStreamTypes | undefined;
  content: string | undefined;
}

export enum EnumStreamTypes {
  Twitch = "twitch",
  Text = "texto",
  Youtube = "youtube",
  Video = "video",
}

export enum EnumPlayerStatus {
  ACTIVE = "ATIVO",
  INACTIVE = "INATIVO",
  BLOCKED = "BLOQUEADO",
}

export interface IChatPhrase {
  id?: number;
  nickname: string;
  avatar: string;
  message: string;
  level: ILevel;
}

export interface IChat {
  phrases: IChatPhrase[];
  isLoading: boolean;
}

export interface IAvatars {
  id: number;
  free: number;
  price: number;
  description: string;
  image_url: string;
  gender: string;
}

export interface INotifications {
  meta: {
    current_page: number;
    last_page: number;
    total: number;
    per_page: number;
    total_unread: number;
  };
  data: INotificationsData[];
}

export interface INotificationsData {
  id: number;
  message: string;
  read: number;
  image_url: string;
  notification: {
    code: string;
  };
  created_at: string;
}

export interface IGetNotifications {
  type: typeof GLOBAL_GET_NOTIFICATIONS;
  payload: IGetNotificationsService;
}

export interface IGetNotificationsService {
  page?: number;
  per_page?: number;
  read?: boolean;
}

export enum EnumAccountCode {
  REAL = "001",
  POINTS = "002",
  PREMIOS = "003",
  BONUS_CASINO = "004",
  BONUS_BINGO = "005",
  PREMIOS_CASINO = "006",
}

export enum EnumClientGroup {
  DEFAULT = 1,
  VIP = 2,
  RESELLER = 3,
}

export enum EnumResellerStatus {
  ACTIVE = "ativo",
  PENDING = "pendente",
  INACTIVE = "inativo",
}

export interface PremiosFaixa {
  id: number;
  valor: number;
  cliques: number;
  nome: string;
  cor: string;
}

export interface ILevels {
  id: number;
  name: string;
  color: string;
  imagem: string;
  bets: number;
  amount: number;
  value: number;
  image_url: string;
}
export interface IBanners {
  id: number;
  name: string;
  link: string;
  image_url: string;
}

export interface IPlayerIndication {
  id: number;
  nickname: string;
  email: string;
  gender: string;
  birthDate: string;
  createdAt: string;
  amount: number;
  active: boolean;
}

export interface IPlayerIndications {
  totalIndications: number;
  totalActiveIndications: number;
  players: IPlayerIndication[] | undefined;
  loading: boolean;
  error: string | undefined;
}

export enum EnumPlayerLevels {
  INICIANTE = "INICIANTE",
  INTERMEDIARIO = "INTERMEDIÁRIO",
  AMADOR = "AMADOR",
  PRO = "PRO",
  MESTRE = "MESTRE",
  LENDA = "LENDA",
  ALLSTAR = "ALL STAR",
}

export enum EnumExchangeType {
  LEVEL = "LEVEL",
  COMISSION = "COMISSION",
}

export enum EnumSmsType {
  InitState,
  ResendedSms,
  SendedSms,
  LoadingSms,
  ErrorSms,
}

export enum EnumControlSmsFormType {
  RegularForm,
  SmsForm,
}
