import { EnumPlayerCardStatus, IBingoGame } from "../games/types";

export const GET_FASTBINGO_CARDS = "GET_FASTBINGO_CARDS";
export const GET_FASTBINGO_CARDS_ERROR = "GET_FASTBINGO_CARDS_ERROR";
export const GET_FASTBINGO_CARDS_FULLFILLED = "GET_FASTBINGO_CARDS_FULLFILLED";

export const FASTBINGO_CARDS = "FASTBINGO_CARDS";
export const FASTBINGO_CARDS_ERROR = "FASTBINGO_CARDS_ERROR";
export const FASTBINGO_CARDS_FULLFILLED = "FASTBINGO_CARDS_FULLFILLED";

export const FASTBINGO_PLAYER_CARDS = "FASTBINGO_PLAYER_CARDS";
export const FASTBINGO_PLAYER_CARDS_FULLFILLED = "FASTBINGO_PLAYER_CARDS_FULLFILLED";
export const FASTBINGO_PLAYER_CARDS_ERROR = "FASTBINGO_PLAYER_CARDS_ERROR";
export const FASTBINGO_RESET_PLAYER_CARDS = "FASTBINGO_RESET_PLAYER_CARDS";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const START_FASTBINGO_GAME = "START_FASTBINGO_GAME";
export const START_FASTBINGO_GAME_ERROR = "START_FASTBINGO_GAME_ERROR";
export const START_FASTBINGO_GAME_FULLFILLED = "START_FASTBINGO_GAME_FULLFILLED";

export const RESET_GAME_READY = "RESET_GAME_READY";

export interface IFastbingoState {
  loading: boolean;
  error: string;
  cards: ICard[];
  playerCards: IPlayerCard[];
  currentFBGameCode: string;
  modal: {
    isOpen: boolean;
  };
  drawnData: IDrawn;
  gameReady: boolean;
}

export interface IDrawn {
  drawnNumbers: [];
  listPlayerCards: IPlayerCard[];
  lastNumber: number | null;
  bingoFullNumber: number | null;
  bingoLineNumber: number | null;
  bingoCornerNumber: number | null;
  winners: IWinners | null;
}

export interface IStart {
  game_code: number | string;
  cards: IStartCard[];
}

export interface IStartCard {
  id: number;
  quantity: number;
}

export interface IPlayerCard {
  id: number;
  status_line: EnumPlayerCardStatus;
  status_corner: EnumPlayerCardStatus;
  status_full: EnumPlayerCardStatus;
  status_ball_screen: EnumPlayerCardStatus;
  numbers: [];
  bingo_full_number?: any;
  bingo_line_number?: any;
  bingo_corner_number?: any;
  bingo_line?: any;
  bingo_corner?: any;
  player_id: number;
  bingo_game_id: number;
  bingo_card_id: number;
  ball_screen: number;
  bingo_game_room_id: undefined | number;
  game_id: number;
  on_game: number;
  bingoCard: IBingoCard;
  bingoGame: IBingoGame;
}

export interface ICard {
  id: number;
  description: string;
  price: number;
  prize_line: number;
  prize_corner: number;
  prize_full: number;
  prize_ball_screen: number;
  room_limit: number;
  default: number;
  currency_id: number;
  prize_currency_id: number;
}

export interface ICardBuyService {
  game_code: string;
  cards: ICardBuyItem[];
}

export interface ICardBuyItem {
  id: number;
  quantity: number;
}

export interface IBingoCard {
  id: number;
  description: string;
  price: number;
  prize_line: number;
  prize_corner: number;
  prize_full: number;
  prize_ball_screen: number;
  room_limit: number;
  default: number;
  currency_id: number;
  prize_currency_id: number;
}

export interface IWinners {
  line: IWinner[];
  corner: IWinner[];
  full: IWinner[];
}

export interface IWinner {
  id: number;
  status_line: EnumPlayerCardStatus;
  status_corner: EnumPlayerCardStatus;
  status_full: EnumPlayerCardStatus;
  player_id: number;
  nickname: string;
}
