import { IReduxAction } from "../../../global";
import { SET_BINGO_GAME_STATUS } from "../games/types";
import { BINGO_GAME_CLEAR_BALLS, IDrawBallState } from "./types";

export const initialState: IDrawBallState = {
  ballsDrawn: undefined,
};

export default function drawBallReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IDrawBallState {
  switch (type) {
    case BINGO_GAME_CLEAR_BALLS:
      return {
        ...state,
        ballsDrawn: undefined,
      };

    case SET_BINGO_GAME_STATUS:
      return {
        ...state,
        ballsDrawn: {
          ...state.ballsDrawn,
          [payload?.gameCode]: payload?.bingoGame?.draw_numbers?.reverse() ?? [],
        },
      };

    default:
      return state;
  }
}
