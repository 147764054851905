import axios, { AxiosRequestConfig } from "axios";

import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookie from "./Cookie";

interface ApiInstanceRequestConfig extends AxiosRequestConfig {
  retryCount?: boolean;
}

const sharedConfig = {
  timeout: 30000,
  retryCount: true,
  headers: {
    "Cache-Control": "no-cache, no-store",
  },
};

function createApiInstance(configs: ApiInstanceRequestConfig) {
  const apiInstance = axios.create({
    withCredentials: true,
    ...sharedConfig,
    ...configs,
  });
  const refreshAuthLogic = (failedRequest: any) =>
  {
    return new Promise((resolve) => {
      if(failedRequest.response.status === 401) {
        Cookie.clearTokens()
        resolve(window.location.replace("/"))
      }
    })
  }
  createAuthRefreshInterceptor(apiInstance, refreshAuthLogic);
  apiInstance.interceptors.request.use((request) => {
      request.headers[
        "Authorization"
      ] = `${Cookie.getTypeCookie()} ${Cookie.getAuthenticationCookie()}`;
    return request;
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === "ECONNABORTED") {
        window.location.reload();
      } else {
        throw error;
      }
    }
  );

  return apiInstance;
}

export default createApiInstance;
