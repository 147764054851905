import { URL_WEBSOCKET } from "../config/constants";

const WebSocketInstance = (playerId: number) => {
  const ws = new WebSocket(`${URL_WEBSOCKET}?playerId=${playerId}`);

  ws.onopen = () => {
    console.log("ws connected");
  };

  return ws;
};

export default WebSocketInstance;
