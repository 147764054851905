import React, { ReactNode, useEffect } from "react";
import classname from "classnames";

import "./ModalGlobal.scss";

export interface IModalGlobalProps {
  children?: ReactNode;
  isOpen?: boolean;
  onClickOverlay?: Function;
  onScrollModal?: () => void;
  id?: string;
}

const ModalGlobal: React.FC<IModalGlobalProps> = ({
  children,
  isOpen = false,
  onClickOverlay = () => null,
  onScrollModal,
  id,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
    }
  }, [isOpen]);

  return (
    <div
      id={id}
      className={classname("modal-global", {
        "modal-global--open": isOpen,
      })}
    >
      <div className="modal-global-overlay" onClick={() => onClickOverlay()} />
      <div className="modal-global-content" onScroll={onScrollModal}>
        <span className="modal-global-content-close" onClick={() => onClickOverlay()}>
          X
        </span>
        {children}
      </div>
    </div>
  );
};

export default ModalGlobal;
