import React from "react";
import classnames from "classnames";

import { IAttributes } from "../../global";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import "./Button.scss";

export enum EnumButtonVariants {
  Primary = "primary",
  Secondary = "secondary",
  Orange = "orange",
  Yellow = "yellow",
}

interface IButtonProps extends IAttributes {
  text?: string;
  variant?: EnumButtonVariants;
  width?: number;
  fontStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  isLoading?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  children,
  text,
  variant,
  width,
  height,
  fontStyle,
  isLoading,
  buttonStyle,
  ...otherProps
}) => {
  return (
    <button
      className={classnames("button", `button--${variant}`)}
      style={{
        width,
        height,
        ...buttonStyle,
      }}
      {...otherProps}
    >
      {children ? (
        children
      ) : (
        <span className="button__text" style={fontStyle}>
          {isLoading ? <Spinner /> : text}
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: EnumButtonVariants.Primary,
  fontSize: 12,
  isLoading: false,
};

export default Button;
