import { IReduxAction } from "../../../global";
import {
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_CONNECT,
  IWebSocketState,
  WEBSOCKET_RECONNECT_ATTEMPT,
  CHAT_MESSAGE,
  CHAT_FLUSH,
} from "./types";

export const initialState: IWebSocketState = {
  attempts: 0,
  ws: undefined,
  sessionId: "",
  chat: [],
};

export default function webSocketReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IWebSocketState {
  switch (type) {
    case WEBSOCKET_CONNECT:
      return {
        ...state,
        attempts: 0,
        ...payload,
      };

    case WEBSOCKET_DISCONNECT:
      return {
        ...state,
        ...payload,
      };

    case WEBSOCKET_RECONNECT_ATTEMPT:
      return {
        ...state,
        ...payload,
      };

    case CHAT_FLUSH:
      return {
        ...state,
        chat: [...payload?.messages],
      };
    case CHAT_MESSAGE:
      return {
        ...state,
        chat: [
          ...state.chat,
          {
            message: payload?.message,
            nickname: payload?.nickname,
            avatar: payload?.avatar,
            level: payload?.level,
          },
        ],
      };

    default:
      return state;
  }
}
