import { EnumPlayerCardStatus, IBingoGame } from "../games/types";

export const GET_GAMES_CARDS = "GET_GAMES_CARDS";
export const GET_GAMES_CARDS_ERROR = "GET_GAMES_CARDS_ERROR";
export const GET_GAMES_CARDS_FULLFILLED = "GET_GAMES_CARDS_FULLFILLED";

export const GAMES_CARDS = "GAMES_CARDS";
export const GAMES_CARDS_ERROR = "GAMES_CARDS_ERROR";
export const GAMES_CARDS_FULLFILLED = "GAMES_CARDS_FULLFILLED";

export const GAMES_PLAYER_CARDS = "GAMES_PLAYER_CARDS";
export const GAMES_PLAYER_CARDS_FULLFILLED = "GAMES_PLAYER_CARDS_FULLFILLED";
export const GAMES_PLAYER_CARDS_ERROR = "GAMES_PLAYER_CARDS_ERROR";
export const GAMES_RESET_PLAYER_CARDS = "GAMES_RESET_PLAYER_CARDS";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const SET_BALL_SCREEN = "SET_BALL_SCREEN";
export const SET_BALL_SCREEN_SUCCESS = "SET_BALL_SCREEN_SUCCESS";
export const SET_BALL_SCREEN_ERROR = "SET_BALL_SCREEN_ERROR";

export interface IGameCardsState {
  loading: boolean;
  error: string;
  cards: ICard[];
  playerCards: IPlayerCard[];
  modal: {
    isOpen: boolean;
  };
  setBallScreen: {
    loading: boolean;
    error?: {
      message: string;
    };
  };
}

export interface IPlayerCard {
  id: number;
  status_line: EnumPlayerCardStatus;
  status_corner: EnumPlayerCardStatus;
  status_full: EnumPlayerCardStatus;
  status_ball_screen: EnumPlayerCardStatus;
  numbers: [];
  bingo_full_number?: any;
  bingo_line_number?: any;
  bingo_corner_number?: any;
  bingo_line?: any;
  bingo_corner?: any;
  player_id: number;
  bingo_game_id: number;
  bingo_card_id: number;
  ball_screen: number;
  bingo_game_room_id: undefined | number;
  game_id: number;
  on_game: number;
  bingoCard: IBingoCard;
  bingoGame: IBingoGame;
}

export interface ICard {
  id: number;
  description: string;
  price: number;
  prize_line: number;
  prize_corner: number;
  prize_full: number;
  prize_ball_screen: number;
  room_limit: number;
  default: number;
  currency_id: number;
  prize_currency_id: number;
}

export interface ICardBuyService {
  game_code: string;
  cards: ICardBuyItem[];
}

export interface ICardBuyItem {
  id: number;
  quantity: number;
}

export interface IBingoCard {
  id: number;
  description: string;
  price: number;
  prize_line: number;
  prize_corner: number;
  prize_full: number;
  prize_ball_screen: number;
  room_limit: number;
  default: number;
  currency_id: number;
  prize_currency_id: number;
}

export interface ISetBallScreenService {
  bingo_game_card_id: number;
  ball_screen: number;
}

export interface ISetBallScreenAction {
  type: typeof SET_BALL_SCREEN;
  payload: ISetBallScreenService;
}
