import React from "react";
import Button from "../Button";
import { IAttributes } from "../../global";
import "./Bubble.scss";

interface IBubbleProps extends IAttributes {
  text?: string;
}

const Bubble: React.FC<IBubbleProps> = ({ text, ...otherProps }) => {
  return (
    <Button className="bubble" {...otherProps}>
      <span className="bubble__text">{text}</span>
    </Button>
  );
};

export default Bubble;
