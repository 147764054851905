import { IReduxAction } from "../../../global";
import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_INITIALIZE,
  WEBSOCKET_RECONNECT_ATTEMPT,
  WEBSOCKET_SEND,
  CHAT_MESSAGE,
  CHAT_FLUSH,
  DRAW_BALL,
  IChatMessage,
} from "./types";

export const initialize = (): IReduxAction => ({
  type: WEBSOCKET_INITIALIZE,
});

export const setInstance = (ws: WebSocket, sessionId: string): IReduxAction => ({
  type: WEBSOCKET_CONNECT,
  payload: {
    ws,
    sessionId,
  },
});

export const destroyInstance = (): IReduxAction => ({
  type: WEBSOCKET_DISCONNECT,
  payload: {
    ws: undefined,
  },
});

export const reconnect = (attempts: number): IReduxAction => ({
  type: WEBSOCKET_RECONNECT_ATTEMPT,
  payload: {
    attempts,
  },
});

export const send = (type: string, data: any): IReduxAction => ({
  type: WEBSOCKET_SEND,
  payload: {
    type,
    data,
  },
});

export const chatMessage = (payload: any = {}) => ({
  type: CHAT_MESSAGE,
  payload,
});

export const chatFlush = (messages: IChatMessage[]) => ({
  type: CHAT_FLUSH,
  payload: {
    messages,
  },
});

export const drawnBall = (payload: { n: number }): IReduxAction => ({
  type: DRAW_BALL,
  payload,
});
