import { IReduxAction } from "../../../global";
import {
  IGlobalState,
  GLOBAL_LOGGED_IN,
  GLOBAL_LOGIN,
  GLOBAL_LOGIN_REJECT,
  GLOBAL_LOGIN_INACTIVE,
  GLOBAL_LOGOUT,
  GLOBAL_MODAL_TOGGLE,
  GLOBAL_SET_AUTH_FORM,
  GLOBAL_GET_STREAM_REQUEST,
  GLOBAL_GET_STREAM_FULTILLED,
  GLOBAL_GET_STREAM_REJECTED,
  GLOBAL_FORGOT_REJECT,
  GLOBAL_FORGOT_SUCCESS,
  GLOBAL_GET_CHAT_FRASES_FULFILLED,
  GLOBAL_GET_CHAT_FRASES,
  EnumStreamTypes,
  GLOBAL_SIGN_UP,
  GLOBAL_SIGN_UP_REJECT,
  GLOBAL_SIGN_UP_FULLFILLED,
  GLOBAL_FORGOT,
  GLOBAL_LOGGED_IN_FULFILLED,
  GLOBAL_EDIT,
  GLOBAL_EDIT_REJECT,
  GLOBAL_EDIT_FULLFILLED,
  GLOBAL_GET_AVATARS,
  GLOBAL_GET_AVATARS_FULFILLED,
  GLOBAL_EXCHANGE,
  GLOBAL_EXCHANGE_ERROR,
  GLOBAL_EXCHANGE_FULLFILLED,
  GLOBAL_VALIDATION,
  GLOBAL_VALIDATION_REJECT,
  GLOBAL_VALIDATION_FULLFILLED,
  GLOBAL_VALIDATION_RESEND,
  GLOBAL_VALIDATION_RESEND_REJECT,
  GLOBAL_VALIDATION_RESEND_FULLFILLED,
  GLOBAL_LOGGED_IN_TRUE,
  GLOBAL_RESET,
  GLOBAL_LEVELS,
  GLOBAL_LEVELS_FULLFILLED,
  GLOBAL_LEVELS_ERROR,
  EnumSmsType,
  EnumControlSmsFormType,
  GLOBAL_GET_BANNERS,
  GLOBAL_GET_BANNERS_FULFILLED,
  GLOBAL_GET_PLAYER_INDICATIONS_ERROR,
  GLOBAL_GET_PLAYER_INDICATIONS_FULFILLED,
  GLOBAL_GET_PLAYER_INDICATIONS,
  GLOBAL_UPDATE_PHONE,
  GLOBAL_UPDATE_PHONE_SUCCESS,
  GLOBAL_UPDATE_PHONE_ERROR,
  GLOBAL_GET_NOTIFICATIONS_SUCCESS,
  GLOBAL_RESET_NOTIFICATIONS,
  GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS,
  GLOBAL_EDIT_RESET,
} from "./types";

export const initialState: IGlobalState = {
  login: "",
  sending: false,
  loggedIn: false,
  finishedLoggeInCheck: false,
  success: false,
  player: {
    id: 0,
    full_name: "",
    nickname: "",
    email: "",
    phone: "",
    gender: "",
    birth_date: "",
    avatar_id: 0,
    avatar: {},
    group: "",
    status: "",
    bets_total: 0,
    drawable_balance: 0,
    comission_balance: 0,
    accounts: [],
    level: {
      id: 0,
      name: "",
      color: "",
      image: "",
      bets: 0,
      amount: 0,
      image_url: "",
      value: 0,
    },
  },
  cliente: {
    id: 0,
    nome: "",
    apelido: "",
    avatar: {},
    grupo: 0,
    statusRepresentante: "",
    casinoApostas: 0,
    faixasPremios: [],
  },
  error: "",
  modalOpen: false,
  authForm: "login",
  stream: { isLoading: false, type: undefined, content: undefined, error: undefined },
  forgot: { isLoading: false, error: undefined, success: false },
  chat: { phrases: [], isLoading: false },
  avatars: [],
  notifications: {
    meta: { total: 0, per_page: 1, total_unread: 0, current_page: 0, last_page: 0 },
    data: [],
  },
  savedPlayer: false,
  smsState: EnumSmsType.InitState,
  smsControlForm: EnumControlSmsFormType.RegularForm,
  exchangeSuccess: false,
  levels: [],
  banners: [],
  playerIndications: null,
  updatePhone: {
    error: "",
    success: false,
    loading: false,
  },
};

export default function globalReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IGlobalState {
  switch (type) {
    case GLOBAL_LOGGED_IN_TRUE: {
      return {
        ...state,
        loggedIn: true,
        finishedLoggeInCheck: true,
      };
    }
    case GLOBAL_EXCHANGE: {
      return {
        ...state,
        sending: true,
        exchangeSuccess: false,
      };
    }
    case GLOBAL_EXCHANGE_ERROR: {
      return {
        ...state,
        sending: false,
        error: payload?.message,
        exchangeSuccess: false,
      };
    }
    case GLOBAL_EXCHANGE_FULLFILLED: {
      return {
        ...state,
        sending: false,
        error: "",
        player: payload?.player,
        exchangeSuccess: true,
      };
    }
    case GLOBAL_LOGIN:
    case GLOBAL_SIGN_UP:
      return {
        ...state,
        sending: true,
      };
    case GLOBAL_LOGIN_INACTIVE:
      return {
        ...state,
        finishedLoggeInCheck: true,
        smsControlForm: EnumControlSmsFormType.SmsForm,
      };
    case GLOBAL_SIGN_UP_FULLFILLED:
      return {
        ...state,
        sending: false,
        error: "",
        modalOpen: false,
        savedPlayer: true,
        smsControlForm: EnumControlSmsFormType.SmsForm,
        ...payload,
      };
    case GLOBAL_SIGN_UP_REJECT:
      return {
        ...initialState,
        ...state,
        sending: false,
        savedPlayer: false,
        error: payload?.message,
      };
    case GLOBAL_VALIDATION_RESEND:
      return {
        ...state,
        sending: true,
        smsState: EnumSmsType.LoadingSms,
      };
    case GLOBAL_VALIDATION_RESEND_REJECT:
      return {
        ...state,
        sending: false,
        error: payload?.message,
        smsState: EnumSmsType.ErrorSms,
      };
    case GLOBAL_VALIDATION_RESEND_FULLFILLED:
      return {
        ...state,
        error: "",
        sending: false,
        smsState: EnumSmsType.ResendedSms,
      };
    case GLOBAL_VALIDATION:
      return {
        ...state,
        sending: true,
        smsState: EnumSmsType.LoadingSms,
      };
    case GLOBAL_VALIDATION_REJECT:
      return {
        ...state,
        sending: false,
        error: payload?.message,
        smsState: EnumSmsType.ErrorSms,
      };
    case GLOBAL_VALIDATION_FULLFILLED:
      return {
        ...state,
        sending: false,
        error: "",
        smsState: EnumSmsType.SendedSms,
      };
    case GLOBAL_EDIT:
      return {
        ...state,
        sending: true,
        error: "",
      };
    case GLOBAL_EDIT_REJECT:
      return {
        ...state,
        sending: false,
        error: payload?.message,
      };
    case GLOBAL_EDIT_FULLFILLED:
      return {
        ...state,
        error: "",
        player: payload?.player,
        success: true,
      };
    case GLOBAL_EDIT_RESET:
      return {
        ...state,
        sending: false,
        success: false,
      };
    case GLOBAL_LOGOUT:
      return {
        ...state,
        ...initialState,
        finishedLoggeInCheck: true,
      };

    case GLOBAL_LOGGED_IN:
    case GLOBAL_LOGGED_IN_FULFILLED:
      return {
        ...state,
        sending: false,
        loggedIn: true,
        finishedLoggeInCheck: true,
        error: "",
        modalOpen: false,
        savedPlayer: true,
        ...payload,
      };
    case GLOBAL_LOGIN_REJECT:
      return {
        ...initialState,
        ...state,
        sending: false,
        finishedLoggeInCheck: true,
        savedPlayer: false,
        error: payload?.message,
      };
    case GLOBAL_MODAL_TOGGLE:
      return {
        ...initialState,
        ...state,
        ...payload,
      };

    case GLOBAL_SET_AUTH_FORM:
      return {
        ...initialState,
        ...state,
        ...payload,
        forgot: {
          isLoading: false,
          success: false,
          error: undefined,
        },
        error: "",
      };

    case GLOBAL_GET_STREAM_REQUEST:
      return {
        ...initialState,
        ...state,
        stream: {
          ...initialState.stream,
          isLoading: true,
        },
      };
    case GLOBAL_GET_STREAM_FULTILLED:
      return {
        ...initialState,
        ...state,
        stream: {
          isLoading: false,
          error: undefined,
          ...(payload as { type: EnumStreamTypes; content: string }),
        },
      };
    case GLOBAL_GET_STREAM_REJECTED:
      return {
        ...initialState,
        ...state,
        stream: {
          ...state.stream,
          isLoading: false,
          error: payload?.message,
        },
      };

    case GLOBAL_FORGOT:
      return {
        ...state,
        forgot: {
          isLoading: true,
          success: false,
          error: undefined,
        },
      };

    case GLOBAL_FORGOT_SUCCESS:
      return {
        ...state,
        forgot: {
          isLoading: false,
          success: true,
          error: undefined,
        },
      };

    case GLOBAL_FORGOT_REJECT:
      return {
        ...state,
        forgot: {
          isLoading: false,
          success: false,
          error: payload?.message,
        },
      };

    case GLOBAL_GET_CHAT_FRASES:
      return {
        ...initialState,
        ...state,
        chat: {
          phrases: [],
          isLoading: true,
        },
      };

    case GLOBAL_GET_CHAT_FRASES_FULFILLED:
      return {
        ...initialState,
        ...state,
        chat: {
          phrases: payload?.phrases,
          isLoading: false,
        },
      };

    case GLOBAL_GET_AVATARS:
      return {
        ...initialState,
        ...state,
        avatars: [],
      };

    case GLOBAL_GET_AVATARS_FULFILLED:
      return {
        ...initialState,
        ...state,
        avatars: payload?.avatars,
      };

    case GLOBAL_RESET_NOTIFICATIONS:
      return {
        ...initialState,
        ...state,
        notifications: {
          ...state.notifications,
          data: [],
        },
      };

    case GLOBAL_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        notifications: {
          meta: payload?.meta,
          data: [...state.notifications.data, ...payload?.data],
        },
      };

    case GLOBAL_GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        notifications: {
          ...state.notifications,
          meta: {
            ...state.notifications.meta,
            total_unread: payload?.total_unread,
          },
        },
      };

    case GLOBAL_RESET:
      return {
        ...state,
        error: "",
        exchangeSuccess: false,
        sending: false,
      };
    case GLOBAL_LEVELS:
      return {
        ...state,
        levels: [],
        error: "",
        sending: true,
      };
    case GLOBAL_LEVELS_FULLFILLED:
      return {
        ...state,
        levels: payload?.levels,
        sending: false,
      };
    case GLOBAL_LEVELS_ERROR:
      return {
        ...state,
        sending: false,
        error: payload?.message,
        levels: [],
      };

    case GLOBAL_GET_BANNERS:
      return {
        ...state,
        banners: [],
      };
    case GLOBAL_GET_BANNERS_FULFILLED:
      return {
        ...state,
        banners: payload?.banners,
      };

    case GLOBAL_GET_PLAYER_INDICATIONS:
      return {
        ...state,
        playerIndications: {
          totalActiveIndications: state.playerIndications?.totalActiveIndications ?? 0,
          totalIndications: state.playerIndications?.totalIndications ?? 0,
          players: [],
          loading: true,
          error: "",
        },
      };

    case GLOBAL_GET_PLAYER_INDICATIONS_FULFILLED:
      return {
        ...state,
        playerIndications: {
          ...payload?.playerIndications,
          loading: false,
        },
      };

    case GLOBAL_GET_PLAYER_INDICATIONS_ERROR:
      return {
        ...state,
        playerIndications: {
          players: undefined,
          totalActiveIndications: state.playerIndications?.totalActiveIndications ?? 0,
          totalIndications: state.playerIndications?.totalIndications ?? 0,
          error: payload?.error,
          loading: false,
        },
      };

    case GLOBAL_UPDATE_PHONE:
      return {
        ...state,
        updatePhone: {
          loading: true,
          success: false,
          error: "",
        },
      };

    case GLOBAL_UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        player: {
          ...state.player,
          phone: payload?.phone,
        },
        updatePhone: {
          loading: false,
          success: true,
        },
      };

    case GLOBAL_UPDATE_PHONE_ERROR:
      return {
        ...state,
        updatePhone: {
          loading: false,
          success: false,
          error: payload?.error,
        },
      };

    default:
      return state;
  }
}
