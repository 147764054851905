import { applyMiddleware, createStore, compose, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { rootSaga, rootReducers } from "./ducks";

const persistedReducer = persistReducer(
  {
    key: "bingotine-root",
    storage,
    // ignore reducers to persist individual state
    blacklist: [
      "global",
      "webSocket",
      "game",
      "checkout",
      "drawBall",
      "modalWinner",
      "externalGames",
      "games",
      "gameCards",
    ],
  },
  rootReducers
);

const bindMiddleware = (...middleware: Middleware[]) =>
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(persistedReducer, compose(bindMiddleware(sagaMiddleware)));

if (process.env.REACT_APP_ENABLE_PERSIST === "true") persistStore(store);

sagaMiddleware.run(rootSaga);
