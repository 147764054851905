import { IReduxAction } from "../../../global";
import {
  GAMES_CATEGORIES,
  GAMES_CATEGORIES_FULLFILLED,
  GAMES_CATEGORIES_ERROR,
  GAME_URL,
  GAME_URL_FULLFILLED,
  GAME_CODE_URL,
  GAME_CODE_URL_FULLFILLED,
  WHOS_WINNING,
  WHOS_WINNING_FULLFILLED,
  WHOS_WINNING_REJECT,
  IGamesCategories,
  IGameUrl,
  IWinning,
  GAMES_VB_RESET_CURRENT,
  GAME_URL_RESET,
  GAMES_VB_SATUS,
  GAMES_VB_SATUS_ERROR,
  GAMES_FB_SATUS,
  GAMES_FB_SATUS_ERROR,
  SET_WINNER_CORNER,
  SET_WINNER_BALL_SCREEN,
  SET_WINNER_FULL,
  SET_WINNER_LINE,
  CLEAN_WINNER,
  SET_LOSER_CORNER,
  SET_LOSER_BALL_SCREEN,
  SET_LOSER_FULL,
  SET_LOSER_LINE,
  CLEAN_LOSER,
  IBingoGameStatus,
  IWinnersTypes,
  IGetBingoGameStatusAction,
  SET_BINGO_GAME_STATUS,
  SET_FAST_GAME_STATUS,
} from "./types";

import { IWinner } from "../games/types";

export const setLoserCorner = (winners: IWinner[]): IReduxAction => ({
  type: SET_LOSER_CORNER,
  payload: {
    winners,
  },
});

export const setLoserBallScreen = (winners: IWinner[]): IReduxAction => ({
  type: SET_LOSER_BALL_SCREEN,
  payload: {
    winners,
  },
});

export const setLoserFull = (winners: IWinner[]): IReduxAction => ({
  type: SET_LOSER_FULL,
  payload: {
    winners,
  },
});

export const setLoserLine = (winners: IWinner[]): IReduxAction => ({
  type: SET_LOSER_LINE,
  payload: {
    winners,
  },
});

export const cleanLoser = (): IReduxAction => ({
  type: CLEAN_LOSER,
});

export const setWinnerCorner = (): IReduxAction => ({
  type: SET_WINNER_CORNER,
});

export const setWinnerBallScreen = (): IReduxAction => ({
  type: SET_WINNER_BALL_SCREEN,
});

export const setWinnerFull = (): IReduxAction => ({
  type: SET_WINNER_FULL,
});

export const setWinnerLine = (): IReduxAction => ({
  type: SET_WINNER_LINE,
});

export const cleanWinner = (): IReduxAction => ({
  type: CLEAN_WINNER,
});

export const gamesCategories = (limit: boolean, value: number): IReduxAction => ({
  type: GAMES_CATEGORIES,
  payload: {
    limit,
    value,
  },
});

export const gamesCategoriesFullfilled = (categories: IGamesCategories[]): IReduxAction => ({
  type: GAMES_CATEGORIES_FULLFILLED,
  payload: {
    categories,
  },
});

export const gamesCategoriesError = (message: string): IReduxAction => ({
  type: GAMES_CATEGORIES_ERROR,
  payload: {
    message,
  },
});

export const getGameUrl = (gameId: number): IReduxAction => ({
  type: GAME_URL,
  payload: {
    gameId,
  },
});

export const getGameUrlFullfilled = (gameUrl: IGameUrl): IReduxAction => ({
  type: GAME_URL_FULLFILLED,
  payload: {
    gameUrl,
  },
});

export const getGameCodeUrl = (code: string): IReduxAction => ({
  type: GAME_CODE_URL,
  payload: {
    code,
  },
});

export const getGameCodeUrlFullfilled = (gameUrl: IGameUrl): IReduxAction => ({
  type: GAME_CODE_URL_FULLFILLED,
  payload: {
    gameUrl,
  },
});

export const resetGameUrl = (): IReduxAction => ({
  type: GAME_URL_RESET,
});

export const listWhosWinning = (): IReduxAction => ({
  type: WHOS_WINNING,
});

export const listWhosWinningError = (message: string): IReduxAction => ({
  type: WHOS_WINNING_REJECT,
  payload: {
    message,
  },
});

export const listWhosWinningFullfilled = (winnings: IWinning[]): IReduxAction => ({
  type: WHOS_WINNING_FULLFILLED,
  payload: {
    winnings,
  },
});

export const resetCurrentVB = (): IReduxAction => ({
  type: GAMES_VB_RESET_CURRENT,
});

export const getVideoBingoStatus = (gameId: number | string): IReduxAction => ({
  type: GAMES_VB_SATUS,
  payload: {
    gameId,
  },
});

export const getVideoBingoStatusError = (message: string): IReduxAction => ({
  type: GAMES_VB_SATUS_ERROR,
  payload: {
    message,
  },
});

export const setVideoBingoStatus = (
  gameCode: string,
  bingoGame: IBingoGameStatus | null,
  winners: IWinnersTypes | null
): IGetBingoGameStatusAction => ({
  type: SET_BINGO_GAME_STATUS,
  payload: { bingoGame, winners, gameCode },
});

export const getFastBingoStatus = (): IReduxAction => ({
  type: GAMES_FB_SATUS,
});

export const getFastBingoStatusError = (message: string): IReduxAction => ({
  type: GAMES_FB_SATUS_ERROR,
  payload: {
    message,
  },
});

export const setFastBingoStatus = (winners: IWinnersTypes | null): IReduxAction => ({
  type: SET_FAST_GAME_STATUS,
  payload: { winners },
});
