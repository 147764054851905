import React, { InputHTMLAttributes } from "react";
import classnames from "classnames";

import "./Input.scss";

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  additionalClass?: string;
  inputStyle?: React.CSSProperties;
}

const Input: React.FC<IInputProps> = ({ type, additionalClass, inputStyle, ...otherProps }) => {
  return (
    <input
      style={{ ...inputStyle }}
      className={classnames("input", additionalClass)}
      type={type}
      {...otherProps}
    />
  );
};

export default Input;
