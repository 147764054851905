import { IReduxAction } from "../../../global";
import {
  GET_FASTBINGO_CARDS,
  GET_FASTBINGO_CARDS_ERROR,
  GET_FASTBINGO_CARDS_FULLFILLED,
  OPEN_MODAL,
  CLOSE_MODAL,
  FASTBINGO_CARDS,
  FASTBINGO_CARDS_ERROR,
  FASTBINGO_CARDS_FULLFILLED,
  ICard,
  IPlayerCard,
  FASTBINGO_PLAYER_CARDS,
  FASTBINGO_PLAYER_CARDS_FULLFILLED,
  FASTBINGO_PLAYER_CARDS_ERROR,
  FASTBINGO_RESET_PLAYER_CARDS,
  START_FASTBINGO_GAME,
  START_FASTBINGO_GAME_ERROR,
  START_FASTBINGO_GAME_FULLFILLED,
  IDrawn,
  RESET_GAME_READY,
} from "./types";

import { SET_BINGO_GAME_STATUS } from "../games/types";

export const refreshDrawnBalls = (drawnBalls: []): IReduxAction => ({
  type: SET_BINGO_GAME_STATUS,
  payload: {
    drawnBalls,
  },
});

export const postGameCards = (params: {}): IReduxAction => ({
  type: FASTBINGO_CARDS,
  payload: {
    ...params,
  },
});

export const postGameCardsError = (message: string): IReduxAction => ({
  type: FASTBINGO_CARDS_ERROR,
  payload: {
    message,
  },
});

export const postGameCardsFullfiled = (gameCard: IPlayerCard): IReduxAction => ({
  type: FASTBINGO_CARDS_FULLFILLED,
  payload: {
    gameCard,
  },
});

export const getGameCards = (): IReduxAction => ({
  type: GET_FASTBINGO_CARDS,
});

export const getGameCardsError = (message: string): IReduxAction => ({
  type: GET_FASTBINGO_CARDS_ERROR,
  payload: {
    message,
  },
});

export const getFastbingoCardsFullfilled = (cards: ICard[]): IReduxAction => ({
  type: GET_FASTBINGO_CARDS_FULLFILLED,
  payload: {
    cards,
  },
});

export const openModalAction = (): IReduxAction => ({
  type: OPEN_MODAL,
});

export const closeModalAction = (): IReduxAction => ({
  type: CLOSE_MODAL,
});

export const getPlayerCards = (gameId?: number | string): IReduxAction => ({
  type: FASTBINGO_PLAYER_CARDS,
  payload: {
    gameId,
  },
});

export const resetPlayerCards = (): IReduxAction => ({
  type: FASTBINGO_RESET_PLAYER_CARDS,
});

export const getPlayerCardsFullfilled = (cards: IPlayerCard[]): IReduxAction => ({
  type: FASTBINGO_PLAYER_CARDS_FULLFILLED,
  payload: {
    cards,
  },
});

export const getPlayerCardsError = (message: string): IReduxAction => ({
  type: FASTBINGO_PLAYER_CARDS_ERROR,
  payload: {
    message,
  },
});

export const startFastBingoGame = (): IReduxAction => ({
  type: START_FASTBINGO_GAME,
});

export const startFastBingoGameError = (message: string): IReduxAction => ({
  type: START_FASTBINGO_GAME_ERROR,
  payload: {
    message,
  },
});

export const startFastBingoGameFullfilled = (params: IDrawn): IReduxAction => ({
  type: START_FASTBINGO_GAME_FULLFILLED,
  payload: {
    ...params,
  },
});

export const resetGameReady = (): IReduxAction => ({
  type: RESET_GAME_READY,
});
