import ApiClient from "../../../services/ApiClient";

export const loadGameCards = () => ApiClient.get("games/fast-bingo/cards");

export const gameCard = (data: any) => ApiClient.post("games/fast-bingo/cards", data);

export const loadPlayerCards = (gameId: number | string) =>
  ApiClient.get(`players/fast-bingo/cards/${gameId}`);

export const startFastBingoGame = () => ApiClient.post(`games/fast-bingo/start`, {});
