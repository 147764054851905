import { IReduxAction } from "../../../global";
import {
  GET_GAMES_CARDS,
  GET_GAMES_CARDS_ERROR,
  GET_GAMES_CARDS_FULLFILLED,
  GAMES_CARDS,
  GAMES_CARDS_ERROR,
  GAMES_CARDS_FULLFILLED,
  OPEN_MODAL,
  CLOSE_MODAL,
  IGameCardsState,
  GAMES_PLAYER_CARDS,
  GAMES_PLAYER_CARDS_FULLFILLED,
  GAMES_PLAYER_CARDS_ERROR,
  GAMES_RESET_PLAYER_CARDS,
  SET_BALL_SCREEN,
  SET_BALL_SCREEN_SUCCESS,
  SET_BALL_SCREEN_ERROR,
} from "./types";
export const initialState: IGameCardsState = {
  error: "",
  loading: false,
  cards: [],
  playerCards: [],
  modal: {
    isOpen: false,
  },
  setBallScreen: {
    loading: false,
  },
};

export default function gameCardsReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IGameCardsState {
  switch (type) {
    case GAMES_CARDS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GAMES_CARDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
      };
    case GAMES_CARDS_FULLFILLED:
      return {
        ...state,
        loading: true,
        playerCards: payload?.gameCard,
      };
    case GET_GAMES_CARDS:
      return {
        ...state,
        loading: true,
        error: "",
        cards: [],
      };
    case GET_GAMES_CARDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
      };
    case GET_GAMES_CARDS_FULLFILLED:
      return {
        ...state,
        loading: false,
        error: "",
        cards: payload?.cards,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: { isOpen: true },
      };
    case CLOSE_MODAL:
      return {
        ...initialState,
        playerCards: state.playerCards,
      };
    case GAMES_PLAYER_CARDS:
      return {
        ...state,
        loading: true,
      };
    case GAMES_PLAYER_CARDS_FULLFILLED:
      return {
        ...state,
        playerCards: payload?.cards,
        loading: false,
      };
    case GAMES_PLAYER_CARDS_ERROR:
    case GAMES_RESET_PLAYER_CARDS:
      return {
        ...state,
        playerCards: [],
        loading: false,
      };

    case SET_BALL_SCREEN:
      return {
        ...state,
        setBallScreen: {
          loading: true,
        },
      };

    case SET_BALL_SCREEN_SUCCESS:
      return {
        ...state,
        setBallScreen: {
          loading: false,
          error: undefined,
        },
      };

    case SET_BALL_SCREEN_ERROR:
      return {
        ...state,
        setBallScreen: {
          loading: false,
          error: payload?.error,
        },
      };
    default:
      return state;
  }
}
