export const GAMES_CATEGORIES = "GAMES_CATEGORIES";
export const GAMES_CATEGORIES_FULLFILLED = "GAMES_CATEGORIES_FULLFILLED";
export const GAMES_CATEGORIES_ERROR = "GAMES_CATEGORIES_ERROR";

export const GAMES_VB_SATUS = "GAMES_VB_SATUS";
export const GAMES_VB_SATUS_ERROR = "GAMES_VB_SATUS_ERROR";

export const GAMES_VB_RESET_CURRENT = "GAMES_VB_RESET_CURRENT";

export const GAMES_FB_SATUS = "GAMES_FB_SATUS";
export const GAMES_FB_SATUS_ERROR = "GAMES_FB_SATUS_ERROR";

export const GAMES_FB_RESET_CURRENT = "GAMES_FB_RESET_CURRENT";

export const GAME_URL = "GAME_URL";
export const GAME_URL_FULLFILLED = "GAME_URL_FULLFILLED";
export const GAME_URL_RESET = "GAME_URL_RESET";

export const GAME_CODE_URL = "GAME_CODE_URL";
export const GAME_CODE_URL_FULLFILLED = "GAME_CODE_URL_FULLFILLED";

export const WHOS_WINNING = "WHOS_WINNING";
export const WHOS_WINNING_REJECT = "WHOS_WINNING_REJECT";
export const WHOS_WINNING_FULLFILLED = "WHOS_WINNING_FULLFILLED";

export const SET_WINNER_CORNER = "SET_WINNER_CORNER";
export const SET_WINNER_BALL_SCREEN = "SET_WINNER_BALL_SCREEN";
export const SET_WINNER_FULL = "SET_WINNER_FULL";
export const SET_WINNER_LINE = "SET_WINNER_LINE";
export const CLEAN_WINNER = "CLEAN_WINNER";

export const SET_LOSER_CORNER = "SET_LOSER_CORNER";
export const SET_LOSER_BALL_SCREEN = "SET_LOSER_BALL_SCREEN";
export const SET_LOSER_FULL = "SET_LOSER_FULL";
export const SET_LOSER_LINE = "SET_LOSER_LINE";
export const CLEAN_LOSER = "CLEAN_LOSER";

export const SET_BINGO_GAME_STATUS = "SET_BINGO_GAME_STATUS";

export const SET_FAST_GAME_STATUS = "SET_FAST_GAME_STATUS";

export enum EnumPlayerCardStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  WINNER = "WINNER",
  LOSER = "LOSER",
}

export enum EnumGameStatus {
  OPENED = "OPENED",
  STARTED = "STARTED",
  STARTING = "STARTING",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
  COMPLETED = "COMPLETED",
  CONCLUDED = "CONCLUDED",
  SCHEDULED = "SCHEDULED",
}

export enum EnumGameWin {
  QUATROCANTOS = "QUATROCANTOS",
  BOLATELA = "BOLATELA",
  CARTELACHEIA = "CARTELACHEIA",
  LINHA = "LINHA",
}

export enum EnumGameLoser {
  QUATROCANTOS = "QUATROCANTOS",
  BOLATELA = "BOLATELA",
  CARTELACHEIA = "CARTELACHEIA",
  LINHA = "LINHA",
}

export interface IBingoGame {
  id: number;
  draw_numbers: number[];
  scheduled_date: Date;
  status: EnumGameStatus;
  round_id: number;
}

export interface IWinner {
  id: number;
  nickname: string;
  prize: string;
}
export interface IWinnersTypes {
  full: IWinner[];
  line?: IWinner[];
  corner?: IWinner[];
}

export interface IRoomWinners {
  id?: number;
  totalWinners: number;
  totalWinnersLine: number;
  totalWinnersCorner: number;
  lastNumber: number;
  lastNumberLine: number;
  lastNumberCorner: number;
  winners: IWinnersTypes;
}

export interface ICardRoomWinners {
  id: string;
  prizeFull: string;
  winnersRooms: IRoomWinners[];
  winnersBallScreen: IWinner[];
}
export interface IBingoGameWinners {
  id: number;
  roundId: number;
  date: Date;
  winners: ICardRoomWinners[];
}

export interface IGamesState {
  message: string;
  loadingGames: boolean;
  isGamesLoaded: boolean;
  categories: IGamesCategories[];
  gameUrl: IGameUrl;
  winnings: IWinning[];
  currentVBGameCode: string;
  bingoGame: { [gameCode: string]: IBingoGame } | null;
  winnerPosition: EnumGameWin | null;
  winner: boolean;
  loser: boolean;
  loserPosition: EnumGameLoser | null;
  winners: IGameWinners[] | null;
  winnersBallScreen: IWinner[] | null;
  winnersLine: IWinner[] | null;
  winnersCorner: IWinner[] | null;
  winnersFull: IWinner[] | null;
}

export interface IGameWinners {
  round_id: number;
  winners: IGameRoomWinner[];
}

export interface IGameRoomWinner {
  bingo_game_room_id: number;
  ball_screen: IWinner[];
  corner: IWinner[];
  full: IWinner[];
  line: IWinner[];
}

export interface IWinner {
  id: number;
  bingo_game_room_id: number;
  amount: number;
  nickname: string;
  player_id: number;
  round_id: number;
  status_ball_screen: string;
  status_corner: string;
  status_full: string;
  status_line: string;
  winners_total: number;
}

export interface IGamesCategories {
  id: number;
  description: string;
  slug: string;
  icon?: string;
  icon_url: string;
  active: number;
  order: number;
  games: IGames[];
}

export interface IGames {
  id: number;
  name: string;
  description?: string;
  image_type: string;
  image: string;
  image_url: string;
  code: string;
  demo: number;
  active: number;
  game_provider_id?: number;
  game_category_id?: number;
  gameProvider: IGameProvider;
}

export interface IGameProvider {
  id: number;
  description: string;
  code: string;
  external: number;
  active: number;
}

export interface IGameUrlAction {
  type: typeof GAME_URL;
  payload: IGameUrl;
}

export interface IGameUrl {
  gameId: number;
  url: string;
  popup: number;
  loading: boolean;
}

export interface IWinning {
  id: number;
  nickname: string;
  amount: number;
  game: IGameWinning;
}

export interface IGameWinning {
  name: string;
  imageUrl: string;
}

export interface IBingoGameStatus {
  id: number;
  status: string;
  drawNumbers: number[];
  scheduledDate: Date;
  startDate: Date;
  endDate: Date;
  bingoCards: [];
  roundId: number;
}

export interface IGetBingoGameStatusAction {
  type: typeof SET_BINGO_GAME_STATUS;
  payload: {
    bingoGame: IBingoGameStatus | null;
    winners: IWinnersTypes | null;
    gameCode: string;
  };
}
