export const IS_DEV = process.env.NODE_ENV === "development";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const URL_WEBSOCKET = process.env.REACT_APP_URL_WEBSOCKET || "";

export const IS_SECURE = API_URL?.indexOf("https") !== -1;

export const PUBLIC_PATH = "/";

export const BROWSER = () => (typeof window !== "undefined" && window.navigator.userAgent) || "";
export const IS_CHROME = () => BROWSER().indexOf("Chrome") !== -1;

export const MAX_WS_CONNECT_ATTEMPTS = 10;

export const DATE_FORMAT = "dd/MM/yyyy";
