import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  GET_GAMES_CARDS,
  GAMES_CARDS,
  ICard,
  IPlayerCard,
  GAMES_PLAYER_CARDS,
  ISetBallScreenAction,
  SET_BALL_SCREEN,
} from "./types";

import {
  getGameCardsError,
  getGamesCardsFullfilled,
  postGameCardsError,
  postGameCardsFullfiled,
  closeModalAction,
  openModalAction,
  getPlayerCardsFullfilled,
  getPlayerCardsError,
  setBallScreenSuccess,
  setBallScreenError,
} from "./actions";

import { loadGameCards, gameCard, loadPlayerCards, ballScreen } from "./api";
import { IRootState } from "../types";
import { rejectApi } from "../../../utils/Functions";

export function* gameCardsSaga() {
  yield all([
    yield takeLatest(GET_GAMES_CARDS, getGameCards),
    yield takeLatest(GAMES_CARDS, postGameCards),
    yield takeLatest(GAMES_PLAYER_CARDS, getPlayerCards),
    yield takeLatest(SET_BALL_SCREEN, setBallScreenWorker),
  ]);
}

function* postGameCards({ payload }: any) {
  try {
    const { data }: { data: IPlayerCard } = yield call(gameCard, payload);
    yield put(postGameCardsFullfiled(data));
    yield put(closeModalAction());
  } catch (err) {
    const error = rejectApi(err);
    yield put(postGameCardsError(error));
  }
}

function* getGameCards({ payload }: any) {
  try {
    const { gameId } = payload;
    const { data }: { data: ICard[] } = yield call(loadGameCards, gameId);
    yield put(getGamesCardsFullfilled(data));
    yield put(openModalAction());
  } catch (err) {
    yield put(getGameCardsError(err));
  }
}

function* getPlayerCards({ payload }: any) {
  try {
    let { gameId } = payload;
    if (!gameId) {
      const { currentVBGameCode } = yield select((state: IRootState) => state.games);
      if (currentVBGameCode) {
        gameId = currentVBGameCode;
      }
    }
    if (gameId) {
      const { data }: { data: IPlayerCard[] } = yield call(loadPlayerCards, gameId);
      yield put(getPlayerCardsFullfilled(data));
    }
  } catch (err) {
    yield put(getPlayerCardsError(err));
    console.error("error", err);
  }
}

function* setBallScreenWorker({ payload }: ISetBallScreenAction) {
  try {
    const { data }: { data: IPlayerCard[] } = yield call(ballScreen, payload);
    yield put(setBallScreenSuccess());
    yield put(getPlayerCardsFullfilled(data));
  } catch (error) {
    yield put(setBallScreenError(error));
  }
}
