import React, { FC, useState } from "react";
import classNames from "classnames";
import { IAttributes } from "../../../global";

import "./BallsList.scss";

export interface IBallsListProps extends IAttributes {
  ballsDrawn: number[];
  lastBall: number;
  audioBalls?: HTMLAudioElement[];
  ballsDrawnCount: number;
}

const BallsList: FC<IBallsListProps> = ({ ballsDrawn, lastBall, audioBalls, ballsDrawnCount }) => {
  const [oldBall, setOldBall] = useState<number>();

  const loadNumberAudio = () => {
    if (lastBall !== oldBall) {
      setOldBall(lastBall);
      if (audioBalls) {
        audioBalls[lastBall]?.play();
      }
    }
  };

  return (
    <div className="wlast-balls">
      <h1>
        BOLAS <span>SORTEADAS</span>
      </h1>
      <h3>{ballsDrawnCount} de 75 bolas</h3>
      <div className="balls row justify-content-m-center align-items-center flex-wrap">
        {ballsDrawn &&
          ballsDrawn.map((ball, key) => (
            <div
              className={classNames("ball row jcc aic", {
                ball1: key === 0,
                ball2: key === 1,
                ball3: key === 2,
              })}
              key={key}
            >
              <span>{ball}</span>
              {loadNumberAudio()}
            </div>
          ))}
      </div>
    </div>
  );
};

export default BallsList;
