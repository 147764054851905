import ApiClient from "../../../services/ApiClient";
import { ISetBallScreenService } from "./types";

export const loadGameCards = (gameId: number | string) =>
  ApiClient.get(`games/video-bingo/cards/${gameId}`);

export const gameCard = (data: any) =>
  ApiClient.post(`games/video-bingo/cards/${data.game_code}`, data);

export const loadPlayerCards = (gameId: number | string) =>
  ApiClient.get(`players/video-bingo/cards/${gameId}`);

export const ballScreen = (payload: ISetBallScreenService) =>
  ApiClient.post(`players/video-bingo/cards/ball-screen`, payload);
