import classNames from "classnames";
import React, { useState } from "react";
import { IAttributes } from "../../global";
import "./ComoJogar.scss";

export interface IComoJogar extends IAttributes {
  color: string;
}

const ComoJogar: React.FC<IComoJogar> = ({ color }) => {
  const [showComoJogar, setShowComoJogar] = useState<boolean>(false);

  const handleShowComoJogar = () => {
    setShowComoJogar(!showComoJogar);
  };

  const comoJogarBox = () => {
    return (
      <div className="wComoJogar">
        <div className={classNames("content", { active: showComoJogar })}>
          <span className="close" onClick={() => handleShowComoJogar()}></span>
          <div className="text">
            <p>
              <strong>Como jogar:</strong>
            </p>
            <br />
            <p>
              Clique em comprar e escolha a quantidade de cartelas que você estará concorrendo no
              sorteio.
            </p>
            <p>São permitidas até 4 cartelas por jogador.</p>
            <br />
            <p>
              <strong>Prêmios:</strong>
            </p>
            <br />
            <p>
              <strong>- 4 Cantos:</strong>
            </p>
            <p>10% do valor do prêmio total que está concorrendo</p>
            <p>
              <strong>- Linha:</strong>
            </p>
            <p>10 % do valor total do prêmio que está concorrendo</p>
            <p>
              <strong>- Cartela cheia:</strong>
            </p>
            <p>100 % do valor do prêmio que está concorrendo</p>
            <br />
            <p>*Caso haja mais de 1 ganhador nos prêmios, o valor é dividido.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="buttonComoJogar" onClick={() => handleShowComoJogar()}>
        <h3 style={{ color }}>COMO JOGAR</h3>
      </div>
      {comoJogarBox()}
    </>
  );
};

export default ComoJogar;
