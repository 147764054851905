import React from "react";
import { useDispatch } from "react-redux";
import { IAttributes } from "../../global";

import { getGameCards } from "../../state/ducks/gameCards/actions";

export interface IBuyCards extends IAttributes {
  type: string;
  index: number;
  code: string;
}

const BuyCardCachorro: React.FC<IBuyCards> = ({ type, index, code }) => {
  const dispatch = useDispatch();

  return (
    <div className="jcc">
      <div
        className={`item item-${index} row aic jcc`}
        onClick={() => dispatch(getGameCards(code))}
      >
        {type === "image" ? (
          <span className="img-card"></span>
        ) : (
          <h3>
            COMPRAR <br /> <span className="blue">CARTELAS</span>
          </h3>
        )}
      </div>
    </div>
  );
};

export default BuyCardCachorro;
