import React, { FC } from "react";

import Input from "../../Input";

export interface IItemProps {
  id: number;
  image: string;
  price: string;
  value: number;
  inputChangeValue(e: React.ChangeEvent<HTMLInputElement>, id: number): any;
  onClickArrowTop(): unknown;
  onClickArrowBottom(): unknown;
  attValue?: number;
  controlActiveCard: number | null;
}

const CartelaItem: FC<IItemProps> = ({
  id,
  image,
  value,
  price,
  inputChangeValue,
  onClickArrowTop,
  onClickArrowBottom,
  controlActiveCard,
}) => {
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    inputChangeValue(e, id);
  };

  return (
    <div className="col col-m-25 col-m-last item column aic">
      <img src={image} alt={`Cartela R$ ${price}`} />
      <p className="value row jcc aic">
        Cartela R$ {price}
        <img src="/cifrao.png" alt={`Cartela R$ ${price}`} width={21} />
      </p>
      <div className="input-numeric">
        <Input
          type="number"
          min={0}
          value={value}
          inputMode="numeric"
          max={4}
          pattern="[0-9]*"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e, id)}
          disabled={controlActiveCard !== null && controlActiveCard !== id}
        />
        <button
          className="arrow arrow--top"
          type="button"
          onClick={onClickArrowTop}
          disabled={value >= 4 || (controlActiveCard !== null && controlActiveCard !== id)}
        ></button>
        <button
          className="arrow arrow--bottom"
          type="button"
          disabled={value <= 0 || (controlActiveCard !== null && controlActiveCard !== id)}
          onClick={onClickArrowBottom}
        ></button>
      </div>
    </div>
  );
};

export default CartelaItem;
