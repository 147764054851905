import { IReduxAction } from "../../../global";
import { GAMES_PLAYER_CARDS } from "../gameCards/types";
import {
  GAMES_CATEGORIES,
  GAMES_CATEGORIES_FULLFILLED,
  GAMES_CATEGORIES_ERROR,
  GAME_URL,
  GAME_URL_FULLFILLED,
  GAME_CODE_URL,
  GAME_CODE_URL_FULLFILLED,
  WHOS_WINNING,
  WHOS_WINNING_REJECT,
  WHOS_WINNING_FULLFILLED,
  IGamesState,
  GAMES_VB_RESET_CURRENT,
  GAME_URL_RESET,
  SET_WINNER_CORNER,
  SET_WINNER_BALL_SCREEN,
  SET_WINNER_FULL,
  SET_WINNER_LINE,
  CLEAN_WINNER,
  SET_LOSER_CORNER,
  SET_LOSER_BALL_SCREEN,
  SET_LOSER_FULL,
  SET_LOSER_LINE,
  CLEAN_LOSER,
  EnumGameWin,
  EnumGameLoser,
  SET_BINGO_GAME_STATUS,
} from "./types";

export const initialState: IGamesState = {
  message: "",
  isGamesLoaded: false,
  loadingGames: false,
  categories: [],
  gameUrl: {
    gameId: 0,
    url: "",
    popup: 0,
    loading: false,
  },
  winnings: [],
  currentVBGameCode: "",
  bingoGame: null,
  winners: null,
  winner: false,
  winnerPosition: null,
  loser: false,
  loserPosition: null,
  winnersBallScreen: null,
  winnersLine: null,
  winnersCorner: null,
  winnersFull: null,
};

export default function gamesReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IGamesState {
  switch (type) {
    case GAMES_CATEGORIES:
      return {
        ...state,
        loadingGames: true,
        message: "",
        categories: [],
        isGamesLoaded: false,
      };
    case GAMES_CATEGORIES_ERROR:
      return {
        ...state,
        loadingGames: false,
        message: payload?.message,
        isGamesLoaded: false,
      };
    case GAMES_CATEGORIES_FULLFILLED:
      return {
        ...state,
        loadingGames: false,
        message: "",
        categories: payload?.categories,
        isGamesLoaded: true,
      };
    case GAME_CODE_URL:
    case GAME_URL:
      return {
        ...state,
        gameUrl: { gameId: 0, url: "", popup: 0, loading: true },
      };
    case GAME_CODE_URL_FULLFILLED:
    case GAME_URL_FULLFILLED:
      return {
        ...state,
        gameUrl: { ...payload?.gameUrl, loading: false },
      };
    case WHOS_WINNING:
      return {
        ...state,
        message: "",
      };
    case WHOS_WINNING_REJECT:
      return {
        ...state,
        message: payload?.message,
        winnings: [],
      };
    case WHOS_WINNING_FULLFILLED:
      return {
        ...state,
        winnings: payload?.winnings,
      };
    case GAMES_VB_RESET_CURRENT:
      return {
        ...state,
        currentVBGameCode: "",
      };
    case GAME_URL_RESET:
      return {
        ...state,
        gameUrl: initialState.gameUrl,
      };
    case GAMES_PLAYER_CARDS:
      return {
        ...state,
        currentVBGameCode: payload?.gameId ?? state.currentVBGameCode,
      };
    case SET_BINGO_GAME_STATUS:
      return {
        ...state,
        bingoGame: { ...state.bingoGame, [payload?.gameCode]: payload?.bingoGame },
        winners: payload?.winners,
      };
    case SET_WINNER_CORNER:
      return {
        ...state,
        winner: true,
        winnerPosition: EnumGameWin.QUATROCANTOS,
      };
    case SET_WINNER_BALL_SCREEN:
      return {
        ...state,
        winner: true,
        winnerPosition: EnumGameWin.BOLATELA,
      };
    case SET_WINNER_FULL:
      return {
        ...state,
        winner: true,
        winnerPosition: EnumGameWin.CARTELACHEIA,
      };
    case SET_WINNER_LINE: {
      return {
        ...state,
        winner: true,
        winnerPosition: EnumGameWin.LINHA,
      };
    }
    case CLEAN_WINNER: {
      return {
        ...state,
        winner: false,
        winnerPosition: null,
        winnersBallScreen: [],
        winnersLine: [],
        winnersFull: [],
        winnersCorner: [],
      };
    }
    case SET_LOSER_CORNER:
      return {
        ...state,
        loser: true,
        loserPosition: EnumGameLoser.QUATROCANTOS,
        winnersCorner: payload?.winners,
      };
    case SET_LOSER_BALL_SCREEN:
      return {
        ...state,
        loser: true,
        loserPosition: EnumGameLoser.BOLATELA,
        winnersBallScreen: payload?.winners,
      };
    case SET_LOSER_FULL:
      return {
        ...state,
        loser: true,
        loserPosition: EnumGameLoser.CARTELACHEIA,
        winnersFull: payload?.winners,
      };
    case SET_LOSER_LINE: {
      return {
        ...state,
        loser: true,
        loserPosition: EnumGameLoser.LINHA,
        winnersLine: payload?.winners,
      };
    }
    case CLEAN_LOSER: {
      return {
        ...state,
        loser: false,
        loserPosition: null,
      };
    }
    default:
      return state;
  }
}
