import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GAMES_CATEGORIES,
  GAME_URL,
  GAME_CODE_URL,
  WHOS_WINNING,
  IWinning,
  IGamesCategories,
  IGameUrl,
  IGameUrlAction,
  GAMES_VB_SATUS,
  GAMES_FB_SATUS,
} from "./types";
import {
  gamesCategoriesFullfilled,
  gamesCategoriesError,
  getGameUrlFullfilled,
  listWhosWinningError,
  listWhosWinningFullfilled,
  resetGameUrl,
  getVideoBingoStatusError,
  setVideoBingoStatus,
  getFastBingoStatusError,
  setFastBingoStatus,
} from "./actions";

import {
  loadGamesCategories,
  loadGameUrl,
  loadGameCodeUrl,
  listWhoswinning,
  loadVideoBingoStatus,
  loadFastBingoStatus,
} from "./api";
import { rejectApi } from "../../../utils/Functions";

export function* gamesSaga() {
  yield all([
    yield takeLatest(GAMES_CATEGORIES, loadGames),
    yield takeLatest(GAME_URL, loadUrl),
    yield takeLatest(GAME_CODE_URL, loadCodeUrl),
    yield takeLatest(WHOS_WINNING, whosWinning),
    yield takeLatest(GAMES_VB_SATUS, getVideoBingoStatus),
    yield takeLatest(GAMES_FB_SATUS, getFastBingoStatus),
  ]);
}

function* loadGames({ payload }: any) {
  try {
    const { limit, value } = payload;
    const { data }: { data: IGamesCategories[] } = yield call(loadGamesCategories, limit, value);
    yield put(gamesCategoriesFullfilled(data));
  } catch (err) {
    const error = rejectApi(err);
    yield put(gamesCategoriesError(error));
  }
}

function* loadUrl({ payload }: IGameUrlAction) {
  try {
    const { gameId } = payload;
    const { data }: { data: IGameUrl } = yield call(loadGameUrl, gameId);
    yield put(getGameUrlFullfilled(data));
  } catch (err) {
    const error = rejectApi(err);
    yield put(gamesCategoriesError(error));
  } finally {
    yield put(resetGameUrl());
  }
}

function* loadCodeUrl({ payload }: any) {
  try {
    const { code } = payload;
    const { data }: { data: IGameUrl } = yield call(loadGameCodeUrl, code);
    yield put(getGameUrlFullfilled(data));
  } catch (err) {
    const error = rejectApi(err);
    yield put(gamesCategoriesError(error));
  } finally {
    yield put(resetGameUrl());
  }
}

function* whosWinning() {
  try {
    const { data }: { data: IWinning[] } = yield call(listWhoswinning);
    yield put(listWhosWinningFullfilled(data));
  } catch (err) {
    const error = rejectApi(err);
    yield put(listWhosWinningError(error));
  }
}

function* getVideoBingoStatus({ payload }: any) {
  try {
    const { gameId } = payload;
    const { data } = yield call(loadVideoBingoStatus, gameId);
    yield put(setVideoBingoStatus(gameId, data?.bingoGame, data?.winners));
  } catch (err) {
    const error = rejectApi(err);
    yield put(getVideoBingoStatusError(error));
  }
}

function* getFastBingoStatus() {
  try {
    const { data } = yield call(loadFastBingoStatus);
    yield put(setFastBingoStatus(data?.winners));
  } catch (err) {
    const error = rejectApi(err);
    yield put(getFastBingoStatusError(error));
  }
}
