import React from "react";

import { IAttributes } from "../../../global";
import Countdown from "react-countdown";
import { padCounter } from "../../../utils/Functions";

import "./RenderCounter.scss";

export interface IRenderCounter extends IAttributes {
  scheduledDate: Date | undefined;
}

const RenderCounter: React.FC<IRenderCounter> = ({ scheduledDate }) => {
  if (scheduledDate === undefined) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <Countdown
        date={scheduledDate}
        renderer={({ hours, minutes, seconds, completed }) => {
          if (completed) {
            return <></>;
          } else {
            return (
              <span className="counter">
                {padCounter(minutes)}:{padCounter(seconds)}
              </span>
            );
          }
        }}
      />
    </React.Fragment>
  );
};

export default RenderCounter;
