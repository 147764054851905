import React, { FC, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ModalGameCards.scss";
import ModalGlobal from "../ModalGlobal";

import { formatCurrency } from "../../../utils/Functions";
import Button from "../../../components/Button";
import { IRootState } from "../../../state/ducks/types";
import CartelaItem from "./CartelaItem";
import { closeModalAction, postGameCards } from "../../../state/ducks/gameCards/actions";
import classNames from "classnames";

const ModalGameCards: FC = () => {
  const dispatch = useDispatch();
  const { cards, modal, error, loading } = useSelector((state: IRootState) => state.gameCards);
  const { currentVBGameCode } = useSelector((state: IRootState) => state.games);
  const [controlActiveCard, setControlActiveCard] = useState<number | null>(null);

  const [selectedCardsQtd, setSelectedCardsQtd] = useState(() => {
    return cards.reduce((aux: any, item) => {
      aux[item.id] = 0;
      return aux;
    }, {});
  });
  const [activeTab, setActiveTab] = useState<number>(1);

  const updateSelectedCard = (id: number, quantity: number) => {
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { value } = e.target;

    if (/^\d+$/.test(value)) {
      const quantity = parseInt(value);
      updateSelectedCard(id, quantity);
      if (quantity === 0) setControlActiveCard(null);
      else setControlActiveCard(id);
    }
  };

  const handlerPlayGame = () => {
    const cards: any = Object.entries(selectedCardsQtd)
      .filter(([id, quantity]) => Number(quantity) > 0)
      .map(([id, quantity]) => {
        return { id, quantity };
      });
    const params = {
      game_code: currentVBGameCode,
      cards: cards,
    };
    dispatch(postGameCards(params));
    eraseSelectedCards();
  };

  const handlerClose = () => {
    dispatch(closeModalAction());
    eraseSelectedCards();
  };

  const eraseSelectedCards = () => {
    setControlActiveCard(null);
    setSelectedCardsQtd(() => {
      return cards.reduce((aux: any, item) => {
        aux[item.id] = 0;
        return aux;
      }, {});
    });
  };

  const handleArrowDown = (id: number) => {
    const quantity: number = (selectedCardsQtd[id] ?? 0) - 1;
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
    if (quantity === 0) setControlActiveCard(null);
  };

  const handleArrowTop = (id: number) => {
    const quantity: number = (selectedCardsQtd[id] ?? 0) + 1;
    setSelectedCardsQtd({
      ...selectedCardsQtd,
      [id]: quantity,
    });
    setControlActiveCard(id);
  };

  return (
    <>
      <ModalGlobal
        id="modal-game-cards"
        isOpen={modal.isOpen}
        onClickOverlay={() => handlerClose()}
      >
        <div className={classNames("loading", { show: loading })}></div>
        <div className="modal-overlay"></div>
        <div className="modal-content">
          <div className="container">
            <div className="wmenu">
              <div className="row flex-m-wrap">
                <div
                  className="menu menu-2 row jcc aic flex-grow-1"
                  onClick={() => setActiveTab(1)}
                >
                  <span>Escolha o valor da sua premiação</span>
                  <img src="/premio.png" alt="Escolha o valor da sua premiação" />
                </div>
              </div>
            </div>
            <div className="wtabs">
              {activeTab === 1 && (
                <div className="tab tab-credits">
                  <div className="row flex-m-wrap">
                    {cards &&
                      cards.map((card, k) => (
                        <Fragment key={k}>
                          <CartelaItem
                            id={card.id}
                            image={`/${card.description}-credit.jpg`}
                            price={formatCurrency(card.price)}
                            value={selectedCardsQtd[card.id] ?? 0}
                            inputChangeValue={handleInputChange}
                            onClickArrowBottom={() => handleArrowDown(card.id)}
                            onClickArrowTop={() => handleArrowTop(card.id)}
                            controlActiveCard={controlActiveCard}
                          />
                        </Fragment>
                      ))}
                  </div>
                  <div className="row jcc container-error">
                    {error && <div className="msg error">{error}</div>}
                  </div>
                  <div className="row row-btns jcc aic">
                    <Button text="JOGAR" onClick={() => handlerPlayGame()} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalGlobal>
    </>
  );
};

export default ModalGameCards;
