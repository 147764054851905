import React, { FC } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  ROUTE_HOME,
  ROUTE_VIDEO_BINGO,
} from "./config/routes";

import Screen from "./components/Screen";
import Videobingo from "./containers/Videobingo";

import ScrollToTop from "./components/Screen/ScrollToTop";

import { IS_DEV } from "./config/constants";

const App: FC = () => {
  React.useEffect(() => {
    if (!IS_DEV) {
      document.onkeydown = function (e) {
        if (e.keyCode === 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "C".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
          return false;
        }
      };
      document.addEventListener("contextmenu", (event) => event.preventDefault());
    }
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <div className="wrapper">
            <Switch>
              <Screen path={ROUTE_VIDEO_BINGO}>
                <Videobingo />
              </Screen>
              <Screen path={ROUTE_HOME}>
                <div className="maintenance">
                  <div className="logo">
                    <img height={50} src="/logo-bingotine.png" alt="Bingotine" />
                  </div>
                  <br />
                  <h2>Sessão expirada.</h2>
                  <br />
                  <h3>Fecha a janela e logue novamente.</h3>
                </div>
              </Screen>
            </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
