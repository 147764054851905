import { EventChannel, eventChannel } from "redux-saga";
import { all, call, delay, put, race, select, take, takeLatest } from "redux-saga/effects";

import { MAX_WS_CONNECT_ATTEMPTS } from "../../../config/constants";
import WebSocketInstance from "../../../services/WebSocket";
import { GLOBAL_LOGOUT } from "../global/types";
import { IRootState } from "../types";
import { chatMessage, destroyInstance, reconnect, setInstance } from "./actions";
import {
  ISendWebsocket,
  CHAT_MESSAGE,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_INITIALIZE,
  WEBSOCKET_RECONNECT_ATTEMPT,
  WEBSOCKET_SEND,
} from "./types";

import { setVideoBingoStatus } from "../games/actions";
import { SET_BINGO_GAME_STATUS } from "../games/types";
import Cookie from "../../../services/Cookie";
import { EnumGameStatus } from "../games/types";
import { getPlayerCards, resetPlayerCards } from "../gameCards/actions";

let ws: WebSocket | undefined = undefined;

export function* webSocketSaga() {
  yield all([
    yield takeLatest(WEBSOCKET_INITIALIZE, connect),
    yield takeLatest(WEBSOCKET_DISCONNECT, disconnected),
    yield takeLatest(WEBSOCKET_RECONNECT_ATTEMPT, connect),
    yield takeLatest(WEBSOCKET_SEND, sendMessages),
  ]);
}

function* connect() {
  const { attempts } = yield select((state: IRootState) => state.webSocket);
  const {
    player: { id: playerId },
  } = yield select((state: IRootState) => state.global);
  if (attempts >= MAX_WS_CONNECT_ATTEMPTS) {
    /*
    try forever, or tell the user to check internet / click in a button to reload
    yield put(showAlert())
    */
    console.log("websocket is down");
    return false;
  }

  const token = Cookie.getAuthenticationCookie();
  if (!token) {
    Cookie.clearTokens();
    localStorage.clear();
    window.location.reload();
    return;
  }
  ws = WebSocketInstance(playerId);

  const channel = yield call(initWebsocket, ws);

  const { disconnected, logout } = yield race({
    task: call(watchMessages, channel),
    disconnected: take(WEBSOCKET_DISCONNECT),
    logout: take(GLOBAL_LOGOUT),
  });

  if (logout || disconnected) {
    channel.close();
  }
}

function* disconnected() {
  const { attempts } = yield select((state: IRootState) => state.webSocket);
  yield delay(4000);
  yield put(reconnect(attempts + 1));
}

function* sendMessages({ payload: { type, ...payload } }: ISendWebsocket) {
  const {
    player: { nickname, avatar, level },
  } = yield select((state: IRootState) => state.global);
  ws?.send(
    JSON.stringify({
      type,
      payload: {
        ...payload,
        nickname,
        avatar: avatar.image_url,
        level,
      },
    })
  );
}

export function* watchMessages(channel: EventChannel<WebSocket>) {
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

const initWebsocket = (ws: WebSocket) => {
  return eventChannel((emit) => {
    ws.onmessage = (message) => {
      try {
        const { type = null, payload = {} } = JSON.parse(message.data);

        switch (type) {
          case "REGISTER":
            return emit(setInstance(ws, payload.sessionId));

          case CHAT_MESSAGE:
            return emit(chatMessage(payload));

          case "BINGO":
            return emit(getPlayerCards());

          case "UPDATE_ROOMS":
            return emit(getPlayerCards());

          case SET_BINGO_GAME_STATUS:
            switch (payload?.bingoGame?.status) {
              case EnumGameStatus.COMPLETED:
                emit(resetPlayerCards());
                break;
              case EnumGameStatus.OPENED:
                emit(getPlayerCards());
                break;

              default:
                break;
            }
            if (payload.gameCode) {
              return emit(
                setVideoBingoStatus(payload.gameCode, payload?.bingoGame, payload?.winners)
              );
            }
            return;

          default:
        }
      } catch (e) {
        console.log("Error parsing message", e);
      }
    };

    ws.onerror = (e) => {
      //console.log("ws error", e);
      return emit(destroyInstance());
    };

    ws.onclose = () => {
      //console.log("ws disconnected");
      return emit(destroyInstance());
    };

    ws.onopen = () => {
      /** TODO: pegar código do vídeo bingo que está aberto */
      // emit(loadVBPlayerCards());
    };

    return () => {
      ws.close();
    };
  });
};
