import React from "react";
import { IAttributes } from "../../global";
import "./ChatMessage.scss";

import { ILevel } from "../../state/ducks/global/types";

interface IChatMessageProps extends IAttributes {
  nickname?: string;
  message?: string;
  avatar?: string;
  level: ILevel;
}

const ChatMessage: React.FC<IChatMessageProps> = ({
  nickname,
  message,
  avatar,
  level,
  ...otherProps
}) => {
  return (
    <div className="chatMessage" {...otherProps}>
      <div
        className="chatMessage__avatar"
        style={{
          backgroundImage: `url("${avatar}")`,
        }}
      >
        {avatar && level && <img className="icon" src={`${level.image_url}`} alt={level.name} />}
      </div>
      <div>
        <span className="chatMessage__name">{nickname}</span>
        <span className="chatMessage__text">{message}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
