import React, { FC, Fragment } from "react";
import { Route} from "react-router-dom";

import "./Screen.scss";
import { IAttributes } from "../../global";

const Screen: FC<IAttributes> = ({
  children,
  ...otherAttributes
}) => {
  
  return (
    <Fragment>
      <Route {...otherAttributes}>{children}</Route>
    </Fragment>
  );
};

export default Screen;
