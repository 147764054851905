import _ from "lodash";
import { EnumPlayerCardStatus } from "../state/ducks/games/types";

// work in progress, rotateMatrix of numbers
const formatCurrency = (value: number, currency?: string) => {
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value);
};

const formatDigits = (value: number) => {
  const newValue: any = value.toFixed(2);
  return newValue.replace(".", ",");
};

const rejectApi = (err: any, reject?: Function): string => {
  const error =
    err?.response?.data?.message || err || "Não foi possível se conectar com o servidor";
  if (reject) reject(error);
  return error;
};

const getErrorMessage = (err: any): string => {
  const error =
    err?.response?.data?.status?.message || err || "Não foi possível se conectar com o servidor";
  return error;
};

const padCounter = (value: number) => value.toString().padStart(2, "0");

export const urlEncode = (params: any) => {
  var lod = _.omitBy(params, _.isNull);
  var query = Object.keys(lod)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(lod[key])}`)
    .join("&");
  return query;
};


function formatFormData(data: string) {
  return Object.keys(data).map((d) => `${d}: ${data[parseInt(d)]}`);
}

function formatDecimal(value: string): number {
  let valor = value.replace(".", "");
  valor = valor.replace(",", ".");
  return parseFloat(valor);
}

function checkGoodOne(
  drawNumbers: number[],
  cardNumbers: (number | null)[][] | undefined,
  statusLine?: string,
  statusCorner?: string
) {
  if (!cardNumbers) return false;
  let goodOne = false;
  goodOne = _.size(_.intersection(drawNumbers, _.compact(_.flatten(cardNumbers)))) >= 23;

  if (goodOne) return true;

  if (statusLine === EnumPlayerCardStatus.ACTIVE) {
    goodOne = cardNumbers.some((l: any, key: number) => {
      const qtdeNumeros = key === 2 ? 3 : 4;
      if (_.size(_.intersection(drawNumbers, _.compact(l))) === qtdeNumeros) {
        return true;
      }
      return false;
    });

    if (goodOne) return true;

    const cardNumbersInvert = cardNumbers
      ? cardNumbers[0].map((_: any, colIndex: number) =>
          cardNumbers.map((row: any) => row[colIndex])
        )
      : [];

    goodOne = cardNumbersInvert.some((l: number[], key: number) => {
      const totalNumbers = key === 2 ? 3 : 4;
      if (_.size(_.intersection(drawNumbers, _.compact(l))) === totalNumbers) {
        return true;
      }
      return false;
    });

    if (goodOne) return true;
  }

  if (statusCorner === EnumPlayerCardStatus.ACTIVE) {
    const corner: number[] = [];
    _.map(cardNumbers, (l: number[], key: number) => {
      if (key === 0 || key === 4) {
        corner.push(l[0]);
        corner.push(l[4]);
      }
    });
    if (_.size(_.intersection(drawNumbers, _.compact(corner))) === 3) {
      return true;
    }
  }

  return false;
}

export {
  formatCurrency,
  rejectApi,
  padCounter,
  getErrorMessage,
  formatFormData,
  formatDecimal,
  formatDigits,
  checkGoodOne,
};
