import { ILevel } from "../global/types";

export const WEBSOCKET_INITIALIZE = "WEBSOCKET_INITIALIZE";

export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";

export const WEBSOCKET_RECONNECT_ATTEMPT = "WEBSOCKET_RECONNECT_ATTEMPT";

export const WEBSOCKET_SEND = "WEBSOCKET_SEND";

export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const CHAT_FLUSH = "CHAT_FLUSH";

export const DRAW_BALL = "DRAW_BALL";

export interface ISendWebsocket {
  type: typeof WEBSOCKET_SEND;
  payload: any;
}

export interface IChatMessage {
  nickname: string;
  message: string;
  avatar: string;
  level: ILevel;
}
export interface IWebSocketState {
  attempts: number;
  ws: WebSocket | undefined;
  sessionId: string;
  chat: IChatMessage[];
}
